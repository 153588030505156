<template>
  <div class="w-100">
    <hb-expansion-panel v-if="contact && billingProfile?.id">
      <template v-slot:title>
        Billing
      </template>

      <template v-slot:content>

        <!-- Payment Cycle -->
        <hb-form 
          label="Payment Cycle"
          :empty="false"
          :full="true"
          :active.sync="active"
          @save="save"
          :editable="hasPermission('edit_contact_billing_settings')"
          @cancel="cancel"
          v-if="billingProfile?.payment_cycles?.length > 0"
        >
          <!-- Payment Cycle On Edit mode -->
          <template v-slot:edit>
            <div>
              <HbSelect
                v-model="getCurrentPaymentCycle"
                :items="paymentCycles"
                placeholder="Payment Cycle"
                id="payment_cycle"
                name="payment_cycle"
                hide-details
                :clearable='false'
              >
                <template v-slot:selection="{ item }">
                  {{ item }}
                </template>
                <template v-slot:item="{ item }">
                  {{ item }}
                </template>
              </HbSelect>
            </div>
          </template>
          <!-- Payment Cycle On Read mode -->
          <template v-slot:display>
            <div>
            {{ getCurrentPaymentCycle }}
            </div>
          </template>
        </hb-form>
        <!-- Billing On -->
        <hb-form
          label="Bill Tenant On"
          :empty="false"
          :full="true"
          :editable="hasPermission('edit_contact_billing_settings')"
          :active.sync="active"
          @save="showEstimates"
          @cancel="cancel"
        >
        <!-- Billing On Editable mode -->
        <template v-slot:edit>
          <div>
            <v-select
              :items="daysOfMonth"
              v-model="form.bill_day"
              class="pa-0 ma-0"
              id="bill_day"
              name="bill_day"
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                {{ item | dayEnding }} of the month
              </template>
              <template v-slot:item="{ item, index }">
                {{ item | dayEnding }}
              </template>
            </v-select>
          </div>
        </template>
        <!-- Billing On Read mode -->
        <template v-slot:display>
          <div>
          {{ $options.filters.dayEnding(form.bill_day) }} of month
          </div>
        </template>
        </hb-form>

        <!-- Invoice Notifications -->
        <hb-form
          label="Invoice Notifications"
          :empty="false"
          :full="true"
          :editable="hasPermission('edit_contact_billing_settings')"
          :active.sync="active"
          @save="save(true)"
          @cancel="cancel"
        >
          <!-- Invoice Notifications Editable mode -->
          <template v-slot:edit>
            <div>
              <v-row>
                <hb-checkbox
                  class="pa-0 ma-0 mt-2 billing-on-item"
                  hide-details
                  id="consecutive_pay"
                  name="consecutive_pay"
                  label="Email tenant monthly invoice"
                  v-model="form.send_invoice"
                />
              </v-row>
              <!-- <v-row class="pl-7">
                <hb-radio-group v-model="form.email_format">
                  <HbRadio
                    label="HTML Email"
                    :disabled="!form.send_invoice"
                    :value="0"
                  />
                  <HbRadio
                    label="Invoice PDF"
                    :disabled="!form.send_invoice"
                    :value="1"
                  />
                </hb-radio-group>
                <hb-radio-group class="pt-3 px-3 mr-3 radio-group-border" v-model="form.email_type">
                  
                  <HbRadio
                    :disabled="!form.send_invoice"
                    :value="0"
                  >
                    <template v-slot:label>
                      Single Invoice for multiple spaces
                    </template>
                    <template v-slot:tooltipHeader>
                      Single Invoice for multiple spaces
                    </template>
                    <template v-slot:tooltipBody>
                      Single Invoice for multiple spaces
                    </template>
                  </HbRadio>
                  <HbRadio
                    :disabled="!form.send_invoice"
                    :value="1"
                  >
                    <template v-slot:label>
                      Separate Invoices
                    </template>
                    <template v-slot:tooltipHeader>
                      Separate Invoices
                    </template>
                    <template v-slot:tooltipBody>
                      Separate Invoices
                    </template>
                  </HbRadio>
                </hb-radio-group>
              </v-row> -->
            </div>
          </template>
          <!-- Invoice Notifications Read mode -->
          <template v-slot:display>
            <div>
            {{ form?.send_invoice ? 'Email Monthly Invoice' : 'Not Emailing Monthly Invoice' }}
            </div>
          </template>
        </hb-form>

        <hb-form
          label="AutoPay"
          :full="true"
          :active.sync="active"
          :editable="hasPermission('edit_contact_billing_settings')"
          @save="save(true)"
          @cancel="cancel"
        >
      <!-- AutoPay Payment Methods -->
      <template v-slot:before >
        <OverviewLeasePaymentMethods
          :autoPaymentMethods="billingProfile.PaymentMethod"
          :paymentMethods="paymentMethods"
          :is_closed="!hasPermission('edit_contact_billing_settings')"
          :property_id="property_id"
          :contact="contact"
          :deny_auto_pay="false"
          :is_billing_profile="true"
          @refreshData="refreshData"
          @setToAutoPay="setToAutoPay"
          @removeAutoPay="removeAutoPay"
          class="mx-n4"
          style="margin-top:-10px;"
        ></OverviewLeasePaymentMethods>
      </template>

        <!-- AutoPay Editable mode -->
        <template v-slot:edit>
          <div class="mt-n2">
            <v-row no-gutters class="mx-0 pa-0">
              <v-col cols="6" class="mb-0 mx-0 pa-0" style="margin-top: 7px; max-width: 7.7em;">
                AutoPay will run
              </v-col>
              <v-col cols="6" class="ma-0 pa-0 px-2">
                <hb-select
                  :items="autopayDays"
                  placeholder="Select"
                  id="auto_pay_after_billing_date"
                  name="auto_pay_after_billing_date"
                  data-vv-as="days"
                  data-vv-scope="info"
                  v-validate="'required'"
                  v-model="form.auto_pay_after_billing_date"
                  :clearable="false"
                  class="mt-1"
                >
                  <template v-slot:selection="data">
                    <span v-if="data.item == 1" class="pb-1">{{ data.item }}-Delinquent
                    </span>
                    <span v-else class="pb-1">{{ data.item }}</span>
                  </template>
                  <template v-slot:item="data">
                    <span v-if="data.item == 1"
                      >{{ data.item }}-Delinquent
                    </span>
                    <span v-else>{{ data.item }}</span>
                  </template>
                </hb-select>
              </v-col>
              <v-col cols="12" class="ma-0 pt-2 pb-0 px-0">
                days after the billing date.
              </v-col>
            </v-row>
          </div>
        </template>
        <!-- AutoPay Read mode -->
        <template v-slot:display>
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0" style="margin: -1px 0 2px 0;" cols="12">
              AutoPay will run
              {{
                form.auto_pay_after_billing_date == 1
                  ? form.auto_pay_after_billing_date + "-Delinquent"
                  : form.auto_pay_after_billing_date
              }}
              days after the billing date.
            </v-col>
          </v-row>
        </template>
        </hb-form>
      </template>
    </hb-expansion-panel>
    <hb-modal
      size="medium"
      confirmation
      title="Confirm Change"
      v-model="displayChangeBillDayModal"
      @close="cancel"
      :showHelpLink="false"
    >
      <template v-slot:content>
        <v-row class="px-7 py-3 hb-font-body">
          <div>You are about to change the default <span class="hb-font-body-medium hb-text-night">Billing On</span> settings for the following spaces:</div>
        </v-row>
        <v-row class="px-7 pb-3">
          <hb-select
            v-model="form.bill_day"
            hide-details
            :items="daysOfMonth"
            item-value="bill_day"
            @change="getEstimates"
            class="pa-0 ma-0"
            :clearable="false"
            dense
          >
            <template v-slot:selection="{ item, index }">
              <span class="hb-font-body-medium hb-text-night">{{ item | dayEnding }} of the month</span>
            </template>
            <template v-slot:item="{ item, index }">
              {{ item | dayEnding }}
            </template>
          </hb-select>
        </v-row>
        <v-row class="px-7 pb-3 hb-font-body">
          <span>Preview and confirm the changes being made.</span>
        </v-row>

        <v-row
          v-if="loading"
          style="height: 200px;"
          align="center"
          justify="center"
          no-gutters
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <v-row v-else 
          class="pb-3 px-7"
          v-for="(lease, i) in contactLeases"
          :key="'contactLeases_' + i"
        >
          <v-row class="px-3 w-100">
            <hb-checkbox
              :value="contactLeases[i].pay"
              v-model="contactLeases[i].pay"
            ></hb-checkbox>
            <span class="hb-font-body-medium hb-text-night">Space {{ lease.Unit.number }}</span>
          </v-row>
          <v-row v-if="lease.prorate_invoice && lease.prorate_invoice.lease_id" class="pl-11 pr-3 hb-lh-0 hb-text-light hb-font-body">
            <v-col cols="6" class="mx-0 px-0">Next Rent Due</v-col>
            <v-col cols="6" class="mx-0 px-0 text-right">{{
              lease.prorate_invoice.due | formatLocalShortDate
            }}</v-col>
          </v-row>
          <v-expansion-panels
            class="hb-expansion-panel"
            multiple
            flat
          >
            <v-expansion-panel class="pl-8 hb-bill-day-panel">
              <v-expansion-panel-header>
                <div class="hb-font-caption">
                  Details
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 ma-0">
                <div v-if="lease.prorate_invoice && lease.prorate_invoice.lease_id">
                  <v-row class="ma-0 pa-0 hb-font-body">
                    <v-col cols="6" class="ma-0 pa-0">Prorate Days</v-col>
                    <v-col cols="6" class="ma-0 pa-0 text-right"
                      >{{ getProRateDay(lease.prorate_invoice) }} days</v-col
                    >
                  </v-row>
                  <v-row
                    class="ma-0 pa-0 hb-font-body"
                    v-for="(lines, i) in lease.prorate_invoice.InvoiceLines"
                    :key="'invoiceLine' + i"
                  >
                    <v-col
                      cols="6"
                      class="ma-0 pa-0"
                      v-if="lines.Service.name == 'Rent'"
                      >Prorated {{ lines.Service.name }}</v-col
                    >
                    <v-col cols="6" class="ma-0 pa-0" v-else>{{
                      lines.Service.name
                    }}</v-col>
                    <v-col cols="6" class="ma-0 pa-0 text-right"
                      >{{ (lines.cost * lines.qty) | formatMoney }}
                    </v-col>
                  </v-row>
                  <v-row class="ma-0 pa-0 hb-font-body">
                    <v-col cols="6" class="ma-0 pa-0">Rental Tax</v-col>
                    <v-col cols="6" class="ma-0 pa-0 text-right"
                      >{{ lease.prorate_invoice.total_tax | formatMoney }}
                    </v-col>
                  </v-row>
                  <v-row class="ma-0 pa-0 pb-2 hb-font-body">
                    <v-col cols="6" class="ma-0 pa-0">Discounts</v-col>
                    <v-col cols="6" class="ma-0 pa-0 text-right"
                      >({{ lease.prorate_invoice.discounts | formatMoney }})
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row v-if="lease.prorate_invoice && lease.prorate_invoice.lease_id" class="font-weight-medium pr-3 pl-11">
            <v-col cols="6" class="ma-0 pa-0">Balance Due</v-col>
            <v-col cols="6" class="ma-0 pa-0 text-right"
              >{{ lease.prorate_invoice.balance | formatMoney }}
            </v-col>
          </v-row>
          <v-row class="w-100" v-if="i + 1 !== contactLeases?.length">
            <v-col col="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-row>
        <v-row class="px-7 pb-3 hb-font-body">
          <div>Select all spaces to be changed.</div>
        </v-row>
      </template>
      <template v-slot:left-actions>
        <span>{{ selectedLeases.length }} of {{ contactLeases.length }} Selected</span>
      </template>
      <template v-slot:right-actions>
        <hb-btn @click="save(false, 'billDay')" :loading="btnLoading" color="secondary" :disabled="selectedLeases?.length <= 0 || loading">Skip Payment</hb-btn>
        <hb-btn @click="processPayment(false)" :loading="btnLoading" :disabled="selectedLeases?.length <= 0 || loading || btnLoading">Continue</hb-btn>
      </template>
    </hb-modal>
    <hb-modal
      size="medium"
      confirmation
      title="Confirm Change"
      v-model="confirmModal"
      @close="cancel"
      :showHelpLink="false"
    >
      <template v-slot:content>
        <v-row class="px-7 py-3 hb-font-body">
          <div v-if="form.payment_method_id !== billingProfile.payment_method_id && !form.payment_method_id">You are about to remove the <span class="hb-font-body-medium hb-text-night">{{ field_name }}</span> for the following spaces:</div>
          <div v-else-if="form.payment_method_id !== billingProfile.payment_method_id">You are about to add the <span class="hb-font-body-medium hb-text-night">{{ field_name }}</span> for the following spaces:</div>
          <div v-else>You are about to change the <span class="hb-font-body-medium hb-text-night">{{ field_name }}</span> from <span class="hb-font-body-medium hb-text-night">{{ old_value }}</span> to <span class="hb-font-body-medium hb-text-night">{{ new_value }}</span> for the following spaces:</div>
        </v-row>

        <v-row 
          class="pb-3 px-7"
          v-for="(lease, i) in contactLeases"
          :key="'contactLeases_' + i"
        >
          <span class="hb-font-body-medium hb-text-night">Space {{ lease.Unit.number }}</span>
        </v-row>

        <v-row class="px-7 py-3 hb-font-body" v-if="new_value !== PAYMENT_CYCLES.MONTHLY && form.payment_cycle !== billingProfile.payment_cycle">
          <div>For any space type where the {{ new_value }} Payment cycle is not applicable, the payment cycle continues to be Monthly</div>
        </v-row>

        <v-row class="px-7 py-3 hb-font-body">
          <div>Do you want to continue with these changes?</div>
        </v-row>
      </template>
      <template v-slot:right-actions>
        <hb-btn @click="save(false)" :loading="btnLoading || loading" :disabled="btnLoading || loading">Continue</hb-btn>
      </template>
    </hb-modal>
    <BillingProfileTakePaymentModal
      v-model="show_take_payment_modal"
      v-if="show_take_payment_modal"
      :contact="contact"
      :lease="selectedLeases[0]"
      source="BILLING_PROFILE"
      @update="takePaymentAndUpdate"
      @close="resetBillingOnField"
    >
    </BillingProfileTakePaymentModal>
  </div>
</template>

<script>
import { calendarMixin } from "../../../mixins/calendarMixin";
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import OverviewLeasePaymentMethods from '../../leases/OverviewLeasePaymentMethods.vue'
import BillingProfileTakePaymentModal from './BillingProfileTakePaymentModal.vue';
import LEASE from "@/constants/lease.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from '../../../EventBus.js';
import moment from 'moment';

export default {
  name: "BillingProfile",
  mixins: [ calendarMixin, notificationMixin ],
  props: {
    contact: {
      type: Object,
      required: true
    },
    property_id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      active: '',
      addPaymentMethod: false,
      selectedAutopaymentMethod: '',
      confirmModal: false,
      billingProfile: {},
      displayChangeBillDayModal: false,
      show_take_payment_modal: false,
      loading: false,
      btnLoading: false,
      old_value: '',
      new_value: '',
      field_name: '',
      prorate_invoices: [],
      contactLeases: [],
      form:{
        contact_id: null,
        property_id: null,
        bill_day: 1,
        auto_pay_after_billing_date: 0,
        payment_method_id: null,
        send_invoice: 0,
        email_format: 0,
        email_type: 0,
        payment_cycle: null,
      },
      paymentCycles: [LEASE.PAYMENT_CYCLES.MONTHLY],
      PAYMENT_CYCLES: LEASE.PAYMENT_CYCLES,
    };
  },
  components: {
    OverviewLeasePaymentMethods,
    BillingProfileTakePaymentModal
  },

  created() {
    this.getBillingProfiles({contact: this.contact}).then(r => {
      this.fetchPaymentMethods();
      this.setFieldsValues();
    });
  },
  methods: {
    ...mapActions({
      getBillingProfiles: 'billingProfileStore/getBillingProfiles',
      updateBillingProfiles: 'billingProfileStore/updateBillingProfiles',
      fetchContactPaymentMethods: 'billingProfileStore/fetchContactPaymentMethods',
    }),
    ...mapMutations({
      setLeases: 'paymentsStore/setLeases',
      setPaymentSource: 'paymentsStore/setPaymentSource',
      pushInvoices : 'paymentsStore/pushInvoices',
      setInvoicesObject : 'paymentsStore/setInvoicesObject',
      resetPayments: 'paymentsStore/resetPayments',
    }),
    showEstimates(){
      if(this.form.bill_day === this.billingProfile.bill_day) return;
      this.displayChangeBillDayModal = true;
      this.setContactLeases();
      this.getEstimates();
    },
    setToAutoPay(item){
      this.form.payment_method_id = item?.id;
      this.save(true);
    },
    removeAutoPay() {
      this.form.payment_method_id = null;
      this.save(true);
    },
    refreshData(pm){
      this.fetchPaymentMethods();
      this.setToAutoPay(pm);
    },
    save(dryrun, scope='general', paymentInfo={}){
      if(dryrun){
        if (!this.displayConfirmationModal()) return;
        this.setContactLeases();
        this.setUpdatedFieldName();
        this.confirmModal = true;
        this.btnLoading = false;
      } else {
        let data = {...this.form, dryrun}
        if(scope === 'billDay'){
          let lease_ids = this.selectedLeases?.map(l => l.id)
          data.lease_ids = lease_ids
          data.payment_details = paymentInfo?.payment_details
        }
        this.update(data);
      }
    },
    takePaymentAndUpdate(paymentInfo={}){
      this.save(false, 'billDay', paymentInfo)
    },
    update(data){
      this.btnLoading = true;
      this.updateBillingProfiles({contact: this.contact, data}).then(r => {
        this.active = null;
        this.showMessageNotification({ type: 'success', description: 'Billing Profile has been updated successfully.' });
        this.confirmModal = false;
        this.getBillingProfiles({contact: this.contact}).then(r => {
          this.setFieldsValues();
        });
        this.btnLoading = false;
        EventBus.$emit('reload_tenant_profile_leases');
      }).catch(error => {
        this.btnLoading = false;
        this.showMessageNotification({type: 'error', description: error});
      })
    },
    getEstimates(){
      let data = {...this.form, dryrun: true}
      this.loading = true;

      this.updateBillingProfiles({contact: this.contact, data}).then(r => {
        this.prorate_invoices = r?.bill_day_data?.map( bdd => bdd.invoices?.change_bill_day_invoices[0]);
        this.invoices = r?.bill_day_data?.map( bdd => bdd.invoices);

        this.contactLeases.map( lease => { 
          let lease_invoices = this.invoices.find( i => i?.change_bill_day_invoices[0]?.lease_id == lease.id );
          lease.prorate_invoice = lease_invoices?.change_bill_day_invoices[0] || [];
          lease.OpenInvoices = lease_invoices?.open_invoices || [];
          lease.AdvanceInvoices = lease_invoices?.change_bill_day_invoices || [];
          lease.billed_months = 0;
        });
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        this.showMessageNotification({type: 'error', description: error});
      })
    },
    processPayment(){
      this.btnLoading = true;
      this.setPaymentSource({ paymentSource: "BILL_DAY_CHANGE" });
      this.setLeases({leases: this.selectedLeases});
      let invoices = [];
      for (let i = 0; i < this.selectedLeases.length; i++) {
        if(this.selectedLeases[i]?.OpenInvoices?.length > 0) invoices.push(...this.selectedLeases[i].OpenInvoices);
        if(this.selectedLeases[i]?.AdvanceInvoices?.length > 0) invoices.push(...this.selectedLeases[i].AdvanceInvoices);
      }
      this.setInvoicesObject({ invoices });
      this.show_take_payment_modal = true;
    },
    resetBillingOnField(){
      this.setFieldsValues();
      this.resetPayments();
      this.active = null;
      this.confirmModal = false;
      this.show_take_payment_modal = false;
      this.displayChangeBillDayModal = false;
      this.btnLoading = false;
    },
    cancel(){
      this.setFieldsValues();
      this.active = null;
      this.confirmModal = false;
      this.show_take_payment_modal = false;
      this.field_name = '';
      this.old_value = ''
      this.new_value = '';
    },
    fetchPaymentMethods(){
      this.fetchContactPaymentMethods({contactID: this.contact?.id, propertyID: this.property_id});
    },
    setFieldsValues(){
      this.setContactLeases();
      this.billingProfile = this.currentBillingProfile(this.contact.id, this.property_id);

      if (this.billingProfile){
        this.form.id = this.billingProfile.id;
        this.form.bill_day = this.billingProfile.bill_day;
        this.form.auto_pay_after_billing_date = this.billingProfile.auto_pay_after_billing_date;
        this.form.payment_method_id = this.billingProfile.payment_method_id;
        this.form.send_invoice = this.billingProfile.send_invoice;
        this.form.email_format = this.billingProfile.email_format;
        this.form.email_type = this.billingProfile.email_type;
        this.form.payment_cycle = this.billingProfile.payment_cycle;
        this.paymentCycles = ['Monthly', ...this.billingProfile?.payment_cycles];
      }
      this.displayChangeBillDayModal = false;

      this.contactLeases = this.contactLeases.map( l => {
        delete l.pay;
        return l
      });
    },
    setContactLeases(){
      this.contactLeases = this?.contact?.Leases.filter(l => (l.end_date === null || moment(l.end_date).startOf('day') > moment().startOf('day')) && l.Unit.property_id == this.property_id);
    },
    getProRateDay(prorate_invoice){
      return this.dateDifferenceInDays(
        moment(prorate_invoice.period_start, "YYYY-MM-DD").startOf(
          "day"
        ),
        moment(prorate_invoice.period_end, "YYYY-MM-DD").startOf(
          "day"
        )
      ) + 1;
    },
    displayConfirmationModal(){

      return ( this.form.auto_pay_after_billing_date !== this.billingProfile.auto_pay_after_billing_date
        || this.form.payment_method_id !== this.billingProfile.payment_method_id
        || (this.form.send_invoice !== this.billingProfile.send_invoice || this.form.email_format !== this.billingProfile.email_format || this.form.email_type !== this.billingProfile.email_type)
        || this.form.payment_cycle !== this.billingProfile.payment_cycle);
    },
    setUpdatedFieldName() {

      if(this.form.send_invoice !== this.billingProfile.send_invoice 
        || this.form.email_format !== this.billingProfile.email_format
        || this.form.email_type !== this.billingProfile.email_type
      ){
        this.field_name = 'Invoice Notification';
        this.old_value = this.billingProfile.send_invoice ? 'Enabled' : 'Disabled';
        // this.old_value += this.billingProfile.email_format ? ", HTML Email" : ', Invoice PDF';
        // this.old_value += this.billingProfile.email_type ? ' and Single Invoice for multiple spaces' : ' and Separate Invoices';

        this.new_value = this.form.send_invoice ? 'Enabled' : 'Disabled';
        // this.new_value += this.form.email_format ? ", HTML Email" : ', Invoice PDF';
        // this.new_value += this.form.email_type ? ' and Single Invoice for multiple spaces' : ' and Separate Invoices';

      } else if(this.form.payment_cycle !== this.billingProfile.payment_cycle){
        this.field_name = 'Payment Cycle';
        this.old_value = this.billingProfile.payment_cycle === null ? this.PAYMENT_CYCLES.MONTHLY : this.billingProfile.payment_cycle;;
        this.new_value = this.getCurrentPaymentCycle;
      } else if(this.form.auto_pay_after_billing_date !== this.billingProfile.auto_pay_after_billing_date){
        this.field_name = 'Autopay After Billing Day';
        this.old_value = this.billingProfile.auto_pay_after_billing_date;
        this.new_value = this.form.auto_pay_after_billing_date;
      } else if(this.form.payment_method_id !== this.billingProfile.payment_method_id){
        this.field_name = 'Autopay Payment Method';
      }
    },
  },
  computed:{
    ...mapGetters({
      currentBillingProfile: 'billingProfileStore/currentBillingProfile',
      paymentMethods: 'billingProfileStore/paymentMethods',
      hasPermission: "authenticationStore/rolePermission",
    }),
    autopayDays() {
      let days = Array(31)
        .fill(0)
        .map((n, i) => i);
      return days;
    },
    getCurrentPaymentCycle: {
      get(){
        return  this.form.payment_cycle === null ? this.PAYMENT_CYCLES.MONTHLY : this.form.payment_cycle;
      },
      set(payment_cycle = null){
        this.form.payment_cycle = payment_cycle === this.PAYMENT_CYCLES.MONTHLY ? null : payment_cycle;
      }
    },
    selectedLeases(){
      return this.contactLeases.filter(l => l.pay);
    }
  },
  watch: {
    property_id(new_value, old_value) {
      this.fetchPaymentMethods();
      this.setFieldsValues();
    },
  }
};
</script>

<style lang="scss" scoped>
  .w-100{
    width: 100%;
  }
  .hb-lh-0{
    line-height: 0px;
  }
  .hb-bill-day-panel {
    background-color: transparent !important;
  }
  button.v-expansion-panel-header, .theme--light.v-expansion-panels.hb-expansion-panel .v-expansion-panel--active > .v-expansion-panel-header{
    min-height: 20px !important;
  }
  .radio-group-border {
    border: 1px solid;
    border-radius: 5px;
    border-color: #DFE3E8;
  }
  span.hb-modal-content .row {
    margin-right: 0px !important;
  }
</style>
