<template>
    <div>
      <v-container class="payment-method-container">
        <v-row v-if="!dense_header">
          <v-col><strong>Direct Debit</strong></v-col>
        </v-row>
        <div v-show="directdebit.length">
          <v-row dense class="mt-0">
            <v-col><strong>Payment Method</strong></v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-radio-group dense v-model="method" :mandatory="false">
                <v-radio value="existing">
                  <template v-slot:label>
                    <v-select
                      :disabled="method !== 'existing'"
                      hide-details
                      :value="payment.payment_method_id"
                      :items="directdebit"
                      item-value="id"
                      item-text="card_end"
                      :error-messages="errors.collect('existing_payment_method_id')"
                      placeholder="Account On File"
                      dense
                      outlined
                      @change="handleUpdate('payment','payment_method_id',$event)"
                      v-validate="method === 'existing'? 'required' : ''"
                      :class="{'custom-field-error' : errors.first('existing_payment_method_id')}"
                      name="existing_payment_method_id"
                      id="existing_payment_method_id"
                      data-vv-name="existing_payment_method_id"
                      data-vv-as="Account On File"
                    >
                    <template v-slot:selection="data" >
                      ****{{data.item.card_end.slice(-4)}}
                    </template>

                   <template v-slot:item="data" >
                      <v-list-item-avatar tile>
                        <v-img src="/img/cc_icons/64/directdebit.png"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>{{data.item.first}} {{data.item.last}} </strong>
                        </v-list-item-title>
                        <v-list-item-subtitle>****{{data.item.card_end.slice(-4)}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    </v-select>
                  </template>
                </v-radio>
  
                <v-radio value="new">
                  <template v-slot:label>
                    <v-icon class="mr-3">mdi-bank</v-icon> New Bank Account
                  </template>
                </v-radio>
              </v-radio-group>
  
            </v-col>
          </v-row>
        </div>
  
        <div v-show="method === 'new'">
          
          <v-row v-if="!dense_header" dense class="mt-2 pb-3">
          <v-col class="pt-2">
            <strong>Account Information</strong>
          </v-col>
          <v-col cols="7" class="d-flex flex-row-reverse" v-if="paymentConfig.SHOW_SAVE_PAYMENT">
            <v-checkbox class="mt-0 pt-0" :disabled="auto_charge" hide-details :input-value="payment_method.save_to_account" @change="updateSaveToAccount($event)" label="Save Payment Method"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="py-0">
            <strong class="label-heading-1">Account Information</strong>
          </v-col>
        </v-row>      
  
          <v-row dense>
            <v-col cols="6">
              <v-text-field
              hide-details
                :class="{'custom-field-error' : errors.first('name_on_card')}"
                :value="payment_method.name_on_card"
                v-validate="'required|max:32'"
                data-vv-name="name_on_card"
                data-vv-as="Account Name"
                :error-messages="errors.collect('name_on_card')"
                label="Account Name*"
                id="card-name"
                name="card-name"
                autocomplete="cc-card-name"
                @input="handleInputUpdate('payment_method','name_on_card',$event)"
                >
              </v-text-field>
            </v-col>
          </v-row>
  
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                :class="{'custom-field-error' : errors.first('bsb_number')}"
                tile
                hide-details
                :value="payment_method.bsb_number"
                data-vv-name="bsb_number"
                v-validate="'required|numeric|length:6'"
                maxlength="6"
                data-vv-as="BSB Number"
                :error-messages="errors.collect('bsb_number')"
                label="BSB Number*"
                id="account-bsb-number"
                name="account-bsb-number"
                autocomplete="cc-account-bsb-number"
                @input="handleInputUpdate('payment_method','bsb_number',$event)"
                >
              </v-text-field>
            </v-col>
          </v-row>
  
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                :class="{'custom-field-error' : errors.first('account_number')}"
                tile
                hide-details
                :value="payment_method.account_number"
                data-vv-name="account_number"
                v-validate="'required|numeric|min:4|max:10|'"
                minlength='4'
                maxlength="10"
                data-vv-as="Account Number"
                :error-messages="errors.collect('account_number')"
                label="Account Number*"
                id="account-number"
                name="account-number"
                autocomplete="cc-account-number"
                @input="handleInputUpdate('payment_method','account_number',$event)"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-container>
  
  
    </div>
  </template>
  
  <script type="text/babel">
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import VuetifyStatepicker from '../VuetifyStatepicker.vue';
    import { mapGetters, mapMutations, mapActions } from "vuex";
    import { EventBus } from '../../../EventBus';
    import { countryStateCityMixin } from '../../../mixins/countryStateCityMixin';
  
    export default {
      mixins: [ countryStateCityMixin ],
      data() {
        return {
          method: null,
        }
      },
      components:{
        HbDatePicker,
        VuetifyStatepicker
      },
      computed:{
        ...mapGetters({
            paymentConfig: 'paymentsStore/getPaymentSourceConfig',
            getErrors: 'paymentsStore/getErrors',
            payment_methods: 'paymentsStore/getContactPaymentMethods',
            payment_method: 'paymentsStore/getPaymentMethod',
            payment: 'paymentsStore/getPayment',
          }),
          directdebit(){
              return this.payment_methods.filter(pm => pm.type === 'directdebit');
          }
      },
      created(){
      },
      mounted() {
          if(this.directdebit.length){
              this.method = 'existing';
          } else {
              this.method = 'new';
          }
      },
      destroyed() {},
      props: ['auto_charge','dense_header','ten_address'],
      methods:{
        ...mapMutations({
          setCheckErrors: 'paymentsStore/setCheckErrors',
          setPayment: 'paymentsStore/setPayment',
          setPaymentMethod: 'paymentsStore/setPaymentMethod',
          setPaymentObject: 'paymentsStore/setPaymentObject',
          setPaymentMethodObject: 'paymentsStore/setPaymentMethodObject',
        }),
        handleUpdate(obj, property_name, property_value){
        if(obj === 'payment'){
          this.setPayment({property: property_name, propertyValue: property_value});
        }
        if(obj === 'payment_method'){
          this.setPaymentMethod({property: property_name, propertyValue: property_value});
          switch(true){
              case property_name === 'country':
                this.setPaymentMethod({property: 'state', propertyValue: ''});
                this.setPaymentMethod({property: 'city', propertyValue: ''});
                this.setPaymentStatesListEvent(this.payment_method.country)
                this.setPaymentCountryCitiesListEvent(this.payment_method.country)
                break;
              case property_name === 'state':
                this.setPaymentMethod({property: 'city', propertyValue: ''});
                this.setPaymentCitiesListEvent(this.payment_method.country, this.payment_method.state)
                break;
              case property_name === 'city':
                this.setPaymentMethod({property: property_name, propertyValue: property_value});
                break;
          }
        }
      },
        handleInputUpdate : _.debounce(async function(obj, property_name, property_value) {
          this.handleUpdate(obj, property_name, property_value);
        }, 200),
        updateSaveToAccount(val){
          this.setPaymentMethod({property: 'save_to_account', propertyValue: !!val}); 
        },
        validateFields(){
          if(this.method === 'existing'){
            return this.$validator.validate('existing_payment_method_id')
          }
          if(this.method === 'new'){
            return this.$validator.validateAll();
          }
        }
      },
      watch:{
        auto_charge(){
          this.setPaymentMethod({property: 'save_to_account', propertyValue: this.auto_charge});
        },
      }
    }
  </script>
  <style scoped>
    .payment-method-container{
      color: #101318;
    }
    .label-heading-1 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #101318;
    }
  </style>