<template>
    <div class="contact-details">

        <div :class="{'overview-panel' : !fromTenantProfile}">

            <div class="pa-0 pt-0 pb-5 slide-out-section">

                <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error" :class="{'mt-6 mb-n3' : fromTenantProfile}"></status>

                <v-expansion-panels v-model="panel" multiple>
                    <!-- =================================== -->
                        <!-- General Information Start -->
                    <!-- =================================== -->

                    <hb-expansion-panel v-if="!nationalAccount">
                        <template v-slot:title>
                            General Information
                        </template>

                        <template v-slot:content>
                            <v-row class="hb-table-row ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="4">
                                    {{ isRentAsBusiness ? 'Business Name' : 'Name' }}
                                </v-col>
                                <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'general_name'}" @click="edit !== 'general_name' ? editRow('general_name') : ''">
                                    <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'general_name'">
                                        <v-col :cols="isRentAsBusiness ? 12 : 4" class="ma-0 pa-0">
                                            <HbTextField
                                                :hide-details="!errors.collect('general.first').length"
                                                v-validate="'required|max:45'"
                                                v-model.lazy="form.first"
                                                :label="isRentAsBusiness ? 'Business name' : 'First'"
                                                single-line
                                                id="first"
                                                :placeholder="isRentAsBusiness ? 'Business name' : 'First'"
                                                :data-vv-as="isRentAsBusiness ? 'Business name' : 'First name'"
                                                data-vv-scope="general"
                                                name="first"
                                                :error-messages="errors.collect('general.first')"
                                                autofocus
                                            ></HbTextField>
                                        </v-col>
                                        <v-col cols="4" class="ma-0 pa-0 px-3" v-show="!isRentAsBusiness">
                                            <HbTextField
                                                :hide-details="!errors.collect('general.middle').length"
                                                v-validate="'max:45'"
                                                v-model.lazy="form.middle"
                                                label="Middle"
                                                single-line
                                                id="middle"
                                                data-vv-as="middle name"
                                                data-vv-scope="general"
                                                name="middle"
                                                :error-messages="errors.collect('general.middle')"
                                            ></HbTextField>
                                        </v-col>
                                        <v-col cols="4" class="ma-0 pa-0"  v-show="!isRentAsBusiness">
                                            <HbTextField
                                                :hide-details="!errors.collect('general.last').length"
                                                :v-validate="isRentAsBusiness ? 'max:45':'required|max:45'"
                                                v-model.lazy="form.last"
                                                label="Last"
                                                single-line
                                                id="last"
                                                data-vv-scope="general"
                                                name="last"
                                                data-vv-as="last name"
                                                :error-messages="errors.collect('general.last')"
                                            ></HbTextField>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 ma-0 mt-5 d-flex align-center">
                                            <hb-btn color="primary" @click="save('general', 'name')" :disabled="contact.first === form.first && contact.middle === form.middle && contact.last === form.last">Save</hb-btn>
                                            <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-else
                                        class="ma-0 pa-0"
                                        @mouseover="hoverRevert(); hovering.general_name = true"
                                        @mouseleave="hovering.general_name = false"
                                    >
                                        <v-col
                                            cols="12"
                                            class="hb-forms-content"
                                        >
                                            <span v-if="contact.salutation || contact.first || contact.middle || contact.last || contact.suffix">
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" class="ma-0 pa-0">
                                                        {{contact.salutation}} {{contact.first}} {{contact.middle}} {{contact.last}} {{contact.suffix}}
                                                    </v-col>
                                                    <v-col cols="1" class="ma-0 pa-0" align="right">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-hover v-slot:default="{ hover }">
                                                                    <span v-on="on">
                                                                        <hb-icon v-if="hovering.general_name || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                    </span>
                                                                </v-hover>
                                                            </template>
                                                            <span>Edit</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </span>
                                            <span v-else>
                                                <hb-link @click="edit !== 'general_name' ? editRow('general_name') : ''">Click to Add</hb-link>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="hb-table-row ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="4">
                                  {{ isRentAsBusiness ? 'Business Email' : 'Email' }}
                                </v-col>
                                <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'general_email'}" @click="edit !== 'general_email' ? editRow('general_email') : ''">
                                    <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'general_email'">
                                        <v-col cols="12" class="pa-0 ma-0">
                                            <HbTextField
                                                :hide-details="!errors.collect('general.email').length"
                                                v-validate="{ max:100, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
                                                v-model.lazy="form.email"
                                                :label="isRentAsBusiness ? 'Business Email' : 'Email'"
                                                single-line
                                                id="email"
                                                data-vv-name="email"
                                                data-vv-scope="general"
                                                name="email"
                                                :error-messages="errors.collect('general.email').length ? form.email ? errors.collect('general.email')[0] : 'Either an email or phone number is required.' : null"
                                                autofocus
                                                @blur="trimEmail"
                                            ></HbTextField>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 ma-0 mt-5 d-flex align-center">
                                            <hb-btn color="primary" @click="save('general', 'email')" :disabled="contact.email === form.email">Save</hb-btn>
                                            <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-else
                                        class="ma-0 pa-0"
                                        @mouseover="hoverRevert(); hovering.general_email = true"
                                        @mouseleave="hovering.general_email = false"
                                    >
                                        <v-col
                                            cols="12"
                                            class="hb-forms-content"
                                        >
                                            <span v-if="contact && contact.email">
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" class="ma-0 pa-0 hb-text-overflow-wrap">
                                                        {{contact.email}}
                                                    </v-col>
                                                    <v-col cols="1" class="ma-0 pa-0" align="right">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-hover v-slot:default="{ hover }">
                                                                    <span v-on="on">
                                                                        <hb-icon v-if="hovering.general_email || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                    </span>
                                                                </v-hover>
                                                            </template>
                                                            <span>Edit</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </span>
                                            <span v-else>
                                                <hb-link @click="edit !== 'general_email' ? editRow('general_email') : ''">Click to Add</hb-link>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="hb-table-row ma-0 pa-0" v-if="form.Phones.length === 0">
                                <v-col class="hb-forms-label" cols="4">
                                    Phone
                                </v-col>
                                <v-col cols="8" class="hb-forms-content hb-form-editable" @click="addPhone(0)">
                                    <hb-link @click="addPhone(0)">Click to Add</hb-link>
                                </v-col>
                            </v-row>
                            <v-row v-else class="hb-table-row ma-0 pa-0" v-for="(phone, i) in form.Phones" :key="i">
                                <v-col class="hb-forms-label" cols="4">
                                    Phone<span v-if="form.Phones.length > 1" class="ml-1">{{i + 1}}</span>
                                </v-col>
                                <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'general_phone' + i}" @click="edit !== 'general_phone' + i ? editRow('general_phone' + i, i) : ''">
                                    <v-row class="mx-0 pb-0 mb-3 mt-3 px-4" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'general_phone' + i">
                                        <v-col cols="12" class="ma-0 pa-0 pb-3">
                                            <v-row class="ma-0 pa-0">
                                                <v-col cols="12" class="hb-z-index-99999 ma-0 pa-0 hb-forms-max-width-498">
                                                    <HbSelect
                                                        :items="phone_types"
                                                        :hide-details="!errors.collect('general.phone_type_' + i).length"
                                                        v-validate="'required'"
                                                        v-model.lazy="phone.type"
                                                        label="Type"
                                                        single-line
                                                        :id="'phone_type_' + i"
                                                        data-vv-scope="general"
                                                        data-vv-as="phone type"
                                                        :name="'phone_type_' + i"
                                                        :error-messages="errors.collect('general.phone_type_' + i)"
                                                    ></HbSelect>
                                                </v-col>
                                                <v-col cols="3" class="hb-z-index-99999 ma-0 pa-0 mt-3 pr-3">
                                                    <HbAutocomplete
                                                        :items="countryCodesList"
                                                        :hide-details="!errors.collect('general.phone_code_' + i).length"
                                                        v-validate="'required'"
                                                        :clearable="false"
                                                        v-model="phoneCode"
                                                        label="Code"
                                                        single-line
                                                        :id="'phone_code_' + i"
                                                        data-vv-scope="general"
                                                        data-vv-as="phone code"
                                                        :name="'phone_code_' + i"
                                                        :error-messages="errors.collect('general.phone_code_' + i)"
                                                        @change="checkValidPhone(phoneCode, phoneNumber)"
                                                        @focusout="checkValidPhone(phoneCode, phoneNumber)"
                                                    >
                                                        <template v-slot:selection="data">
                                                            +{{data.item}}
                                                        </template>
                                                        <template v-slot:item="data">
                                                            +{{data.item}}
                                                        </template>
                                                    </HbAutocomplete>
                                                </v-col>
                                                <v-col cols="4" class="hb-z-index-99999 ma-0 pa-0 mt-3 pr-3">
                                                    <HbTextField
                                                        v-validate="'max:45'"
                                                        v-model="phoneNumber"
                                                        label="Number"
                                                        single-line
                                                        :id="'phone_number_' + i"
                                                        data-vv-scope="general"
                                                        data-vv-as="phone number"
                                                        :name="'phone_number_' + i"
                                                        @change="checkValidPhone(phoneCode, phoneNumber)"
                                                        :error-messages="errors.collect('general.phone_number_' + i).length ? errors.collect('general.phone_number_' + i) : !checkValidPhone(phoneCode, phoneNumber) ? 'Please Enter a Valid Phone Number' : null"                                                        ></HbTextField>
                                                </v-col>
                                                <v-col cols="4" class="hb-z-index-99999 ma-0 pa-0 mt-3">
                                                    <hb-checkbox
                                                        :id="'sms_' + i"
                                                        :name="'sms_' + i"
                                                        :data-name="'sms_' + i"
                                                        v-model="phone.sms"
                                                    >
                                                        <template v-slot:label>
                                                            <span class="text-capitalize">SMS</span>
                                                        </template>
                                                    </hb-checkbox>
                                                </v-col>
                                                <v-spacer></v-spacer>
                                                <v-col cols="1" class="ma-0 pa-0 pt-6 align-right" v-if="i + 1 <= contact.Phones.length && i !== 0">
                                                    <hb-icon @click="confirmRemovePhone = true" class="ma-0 pa-0 hand">mdi-close</hb-icon>
                                                </v-col>

                                                <hb-modal v-model="confirmRemovePhone" show-help-link v-if="confirmRemovePhone" size="medium" title="Delete Phone Number" confirmation>
                                                    <template v-slot:content>
                                                        <div class="py-4 px-6">
                                                            Are you sure you want to delete the selected phone number?
                                                            <br /><br />
                                                            This action cannot be undone.
                                                        </div>
                                                    </template>
                                                    <template v-slot:actions>
                                                        <hb-btn color="destructive" @click="deletePhone(phone, i)">Delete</hb-btn>
                                                    </template>
                                                </hb-modal>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 ma-0 mt-1 d-flex align-center">
                                            <hb-btn color="primary" @click="save('general', 'phone', i)">Save</hb-btn>
                                            <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-else
                                        class="ma-0 pa-0"
                                        @mouseover="hoverRevert(); hovering.general_phone = true, current_row_hovered = 'general_phone' + i"
                                        @mouseleave="hovering.general_phone = false, current_row_hovered = 'general_phone' + i"
                                    >
                                        <v-col
                                            cols="12"
                                            class="hb-forms-content"
                                        >
                                            <span v-if="phone">
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" class="ma-0 pa-0">
                                                        <div>
                                                            <span class="pr-2">{{phone.type}}</span>{{phone.phone | formatPhone}}
                                                            <span v-if="phone.sms" class="pl-1 hb-text-light">Can SMS</span>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="1" class="ma-0 pa-0" align="right">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-hover v-slot:default="{ hover }">
                                                                    <span v-on="on">
                                                                        <hb-icon v-if="(hovering.general_phone && current_row_hovered === 'general_phone' + i) || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                    </span>
                                                                </v-hover>
                                                            </template>
                                                            <span>Edit</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </span>
                                        </v-col>
                                        <v-col v-if="form.Phones.length === i + 1" cols="12" class="hb-forms-content pt-0">
                                            <hb-link @click="addPhone(i + 1)">+ Add Phone Number</hb-link>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="hb-table-row ma-0 pa-0" v-show="isRentAsBusiness">
                                <v-col class="hb-forms-label" cols="4">
                                    Taxpayer ID
                                    <hb-tooltip> 
                                        <template v-slot:body>
                                            <div style="width: 250px">
                                                Taxpayer ID is a 9 digits identifying number used for tax purposes in the United States and in other countries under the Common Reporting Standard.
                                            </div>
                                        </template>
                                    </hb-tooltip>
                                </v-col>
                                <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'general_taxpayer_number'}" @click="edit !== 'general_taxpayer_number' ? editRow('general_taxpayer_number') : ''">
                                    <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'general_taxpayer_number'">
                                        <v-col cols="12" class="pa-0 ma-0">
                                            <HbTextField
                                                :hide-details="!errors.collect('general.taxpayer_number').length"
                                                v-validate="'numeric|min:9|max:9'"
                                                v-model.lazy="form.taxpayer_number"
                                                v-mask="'#########'"
                                                label="Taxpayer ID"
                                                single-line
                                                id="taxpayer_number"
                                                data-vv-as="Taxpayer ID"
                                                data-vv-scope="general"
                                                name="taxpayer_number"
                                                :error-messages="errors.collect('general.taxpayer_number')"
                                                autofocus
                                            ></HbTextField>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 ma-0 mt-5 d-flex align-center">
                                            <hb-btn color="primary" @click="save('general', 'taxpayer_number')" :disabled="contact.taxpayer_number === form.taxpayer_number">Save</hb-btn>
                                            <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-else
                                        class="ma-0 pa-0"
                                        @mouseover="hoverRevert(); hovering.general_taxpayer_number = true"
                                        @mouseleave="hovering.general_taxpayer_number = false"
                                    >
                                        <v-col
                                            cols="12"
                                            class="hb-forms-content"
                                        >
                                            <span v-if="contact && contact.taxpayer_number">
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" class="ma-0 pa-0">
                                                        {{contact.taxpayer_number}}
                                                    </v-col>
                                                    <v-col cols="1" class="ma-0 pa-0" align="right">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-hover v-slot:default="{ hover }">
                                                                    <span v-on="on">
                                                                        <hb-icon v-if="hovering.general_taxpayer_number || $vuetify.breakpoint.xs" v-on="on" small :class="hover ? 'hb-text-night' : ''">mdi-pencil</hb-icon>
                                                                    </span>
                                                                </v-hover>
                                                            </template>
                                                            <span>Edit</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </span>
                                            <span v-else>
                                                <hb-link @click="edit !== 'general_taxpayer_number' ? editRow('general_taxpayer_number') : ''">Click to Add</hb-link>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="hb-table-row-textarea ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="4">
                                    Internal Notes
                                </v-col>
                                <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'general_notes'}" @click="edit !== 'general_notes' ? editRow('general_notes') : ''">
                                    <v-row class="mx-0 pb-0 mb-3 mt-3 px-4" :style="fromTenantProfile ? 'margin-top:-5px;' : 'margin-top:-6px'" v-if="edit === 'general_notes'">
                                        <v-col cols="12" class="pa-0 ma-0">
                                            <v-textarea
                                                :hide-details="!errors.collect('general.notes').length"
                                                v-validate="'max:1000'"
                                                data-vv-as="notes"
                                                v-model.lazy="form.notes"
                                                label="Enter Notes"
                                                single-line
                                                id="notes"
                                                data-vv-scope="general"
                                                name="notes"
                                                :error-messages="errors.collect('general.notes')"
                                                auto-grow
                                                autofocus
                                                rows="1"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 ma-0 mt-5 d-flex align-center">
                                            <hb-btn color="primary" @click="save('general', 'notes')" :disabled="contact.notes === form.notes">Save</hb-btn>
                                            <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-else
                                        class="ma-0 pa-0"
                                        @mouseover="hoverRevert(); hovering.general_notes = true"
                                        @mouseleave="hovering.general_notes = false"
                                    >
                                        <v-col
                                            cols="12"
                                            class="hb-forms-content"
                                        >
                                            <span v-if="contact && contact.notes">
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" class="ma-0 pa-0">
                                                        {{contact.notes}}
                                                    </v-col>
                                                    <v-col cols="1" class="ma-0 pa-0" align="right">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-hover v-slot:default="{ hover }">
                                                                    <span v-on="on">
                                                                        <hb-icon v-if="hovering.general_notes || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                    </span>
                                                                </v-hover>
                                                            </template>
                                                            <span>Edit</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </span>
                                            <span v-else>
                                                <hb-link @click="edit !== 'general_notes' ? editRow('general_notes') : ''">Click to Add</hb-link>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="hb-table-row ma-0 pa-0"  v-show="!isRentAsBusiness">
                                <v-col class="hb-forms-label" cols="4">
                                    Date of Birth
                                </v-col>
                                <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'general_birthdate'}" @click="edit !== 'general_birthdate' ? editRow('general_birthdate') : ''">
                                    <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'general_birthdate'">
                                        <v-col cols="12" class="hb-z-index-99999 pa-0 ma-0">
                                            <hb-date-picker
                                                @click:clear="form.dob = ''"
                                                label="MM/DD/YYYY"
                                                :single-line="true"
                                                :clearable="true"
                                                :solo="false"
                                                id="dob"
                                                data-vv-as="date of birth"
                                                data-vv-scope="general"
                                                v-model="form.dob"
                                            ></hb-date-picker>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 ma-0 mt-5 d-flex align-center">
                                            <hb-btn color="primary" @click="save('general', 'dob')" :disabled="contact.dob === form.dob">Save</hb-btn>
                                            <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-else
                                        class="ma-0 pa-0"
                                        @mouseover="hoverRevert(); hovering.general_dob = true"
                                        @mouseleave="hovering.general_dob = false"
                                    >
                                        <v-col
                                            cols="12"
                                            class="hb-forms-content"
                                        >
                                            <span v-if="contact && contact.dob">
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" class="ma-0 pa-0">
                                                        {{contact.dob | formatDateServices}}
                                                    </v-col>
                                                    <v-col cols="1" class="ma-0 pa-0" align="right">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-hover v-slot:default="{ hover }">
                                                                    <span v-on="on">
                                                                        <hb-icon v-if="hovering.general_dob || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                    </span>
                                                                </v-hover>
                                                            </template>
                                                            <span>Edit</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </span>
                                            <span v-else>
                                                <hb-link @click="edit !== 'general_birthdate' ? editRow('general_birthdate') : ''">Click to Add</hb-link>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="hb-table-row ma-0 pa-0"  v-show="!isRentAsBusiness">
                                <v-col class="hb-forms-label" cols="4">
                                    Social Security #
                                </v-col>
                                <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'general_ssn'}" @click="edit !== 'general_ssn' ? editRow('general_ssn') : ''">
                                    <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'general_ssn'">
                                        <v-col cols="12" class="pa-0 ma-0">
                                            <HbTextField
                                                :hide-details="!errors.collect('general.ssn').length"
                                                v-model.lazy="form.ssn"
                                                label="###-##-####"
                                                single-line
                                                v-mask="'###-##-####'"
                                                v-validate="'max:45'"
                                                id="ssn"
                                                data-vv-name="ssn"
                                                data-vv-scope="general"
                                                name="ssn"
                                                :error-messages="errors.collect('general.ssn')"
                                                autofocus
                                            ></HbTextField>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 ma-0 mt-5 d-flex align-center">
                                            <hb-btn color="primary" @click="save('general', 'ssn')" :disabled="contact.ssn === form.ssn">Save</hb-btn>
                                            <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-else
                                        class="ma-0 pa-0"
                                        @mouseover="hoverRevert(); hovering.general_ssn = true"
                                        @mouseleave="hovering.general_ssn = false"
                                    >
                                        <v-col
                                            cols="12"
                                            class="hb-forms-content"
                                        >
                                            <span v-if="contact && contact.ssn">
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" class="ma-0 pa-0">
                                                        ###-##-{{contact.ssn.toString().slice(-4)}}
                                                    </v-col>
                                                    <v-col cols="1" class="ma-0 pa-0" align="right">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-hover v-slot:default="{ hover }">
                                                                    <span v-on="on">
                                                                        <hb-icon v-if="hovering.general_ssn || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                    </span>
                                                                </v-hover>
                                                            </template>
                                                            <span>Edit</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </span>
                                            <span v-else>
                                                <hb-link @click="edit !== 'general_ssn' ? editRow('general_ssn') : ''">Click to Add</hb-link>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="hb-table-row ma-0 pa-0"  v-show="!isRentAsBusiness">
                                <v-col class="hb-forms-label" cols="4">
                                    Gender
                                </v-col>
                                <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'general_gender'}" @click="edit !== 'general_gender' ? editRow('general_gender') : ''">
                                    <v-row class="ma-0 pb-0 mt-3 mb-3 px-4" v-if="edit === 'general_gender'">
                                        <v-col cols="12" class="pa-0 ma-0">
                                            <hb-radio-group row v-model="form.gender" class="ma-0 pa-0">
                                                <hb-radio value="female">
                                                    <template v-slot:label>
                                                        Female
                                                    </template>
                                                </hb-radio>

                                                <hb-radio value="male">
                                                    <template v-slot:label>
                                                        Male
                                                    </template>
                                                </hb-radio>

                                                <hb-radio value="no response" class="py-3">
                                                    <template v-slot:label>
                                                        No Response
                                                    </template>
                                                </hb-radio>
                                            </hb-radio-group>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 ma-0 mt-n4 d-flex align-center">
                                            <hb-btn color="primary" @click="save('general', 'gender')" :disabled="(form && !form.gender) || contact.gender === form.gender">Save</hb-btn>
                                            <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-else
                                        class="ma-0 pa-0"
                                        @mouseover="hoverRevert(); hovering.general_gender = true"
                                        @mouseleave="hovering.general_gender = false"
                                    >
                                        <v-col
                                            cols="12"
                                            class="hb-forms-content"
                                        >
                                            <span v-if="contact && contact.gender" class="text-capitalize">
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" class="ma-0 pa-0">
                                                        {{contact.gender}}
                                                    </v-col>
                                                    <v-col cols="1" class="ma-0 pa-0" align="right">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-hover v-slot:default="{ hover }">
                                                                    <span v-on="on">
                                                                        <hb-icon v-if="hovering.general_gender || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                    </span>
                                                                </v-hover>
                                                            </template>
                                                            <span>Edit</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </span>
                                            <span v-else>
                                                <hb-link @click="edit !== 'general_gender' ? editRow('general_gender') : ''">Click to Add</hb-link>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row class="hb-table-row ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="4">
                                    Driver's License
                                </v-col>
                                <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'general_license'}" @click="edit !== 'general_license' ? editRow('general_license') : ''">
                                    <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'general_license'">
                                        <v-col cols="12" class="ma-0 pa-0 mb-n1">
                                            <HbTextField
                                                v-model.lazy="form.driver_license"
                                                v-validate="'max:45'"
                                                label="Number"
                                                single-line
                                                id="driver_license"
                                                data-vv-name="driver_license"
                                                data-vv-scope="general"
                                                data-vv-as="driver's license"
                                                name="driver_license"
                                                autofocus
                                                :hide-details="!errors.collect('general.driver_license').length"
                                                :error-messages="errors.collect('general.driver_license')"
                                            ></HbTextField>
                                        </v-col>
                                        <v-col cols="12" class="hb-z-index-99999 ma-0 pa-0">
                                            <hb-date-picker
                                                @click:clear="form.driver_license_exp = ''"
                                                dateFormat='MM-DD-YYYY'
                                                mask="##-##-####"
                                                label="Expiration Date (MM-DD-YYYY)"
                                                :single-line="true"
                                                :clearable="true"
                                                :solo="false"
                                                :canWrite="true"
                                                :min="currentDate"
                                                data-vv-as="Driver's License Expiration Date"
                                                data-vv-scope="general"
                                                v-model="form.driver_license_exp"
                                            ></hb-date-picker>
                                        </v-col>
                                        <v-col cols="6" class="hb-z-index-99999 ma-0 pa-0 pr-3 mt-3">
                                            <HbAutocomplete
                                                :items="countriesListMixin"
                                                :hide-details="!errors.collect('general.driver_license_country').length"
                                                v-validate="'required'"
                                                v-model="form.driver_license_country"
                                                label="Country"
                                                single-line
                                                id="driver_license_country"
                                                data-vv-scope="general"
                                                data-vv-as="Driver's License Country"
                                                name="driver_license_country"
                                                :error-messages="errors.collect('general.driver_license_country')"
                                                @change="setDLCountry"
                                            >
                                            </HbAutocomplete>
                                        </v-col>
                                        <v-col cols="6" class="hb-z-index-99999 ma-0 pa-0 mt-3">
                                            <!-- <vuetify-statepicker
                                                id="state"
                                                name="state"
                                                :single_line="true"
                                                v-model="form.driver_license_state"
                                            ></vuetify-statepicker> -->
                                            <HbAutocomplete
                                                :items="stateDLListMixin"
                                                v-validate="stateDLListMixin.length ? 'required' : ''"
                                                v-model="form.driver_license_state"
                                                item-text="name"
                                                item-value="value"
                                                label="State"
                                                id="driver_license_state"
                                                name="state"
                                                single-line
                                                data-vv-scope="general"
                                                data-vv-name="driver_license_state"
                                                data-vv-as="Driver's License State"
                                                :error="errors.collect('general.driver_license_state').length > 0"
                                            >
                                            </HbAutocomplete>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 ma-0 mt-5 d-flex align-center">
                                            <hb-btn color="primary" @click="save('general', 'driver_license')" :disabled="contact.driver_license === form.driver_license && (contact.driver_license_exp === form.driver_license_exp) && (contact.driver_license_state === form.driver_license_state && contact.driver_license_country === form.driver_license_country)">Save</hb-btn>
                                            <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-else
                                        class="ma-0 pb-0"
                                        @mouseover="hoverRevert(); hovering.general_license = true"
                                        @mouseleave="hovering.general_license = false"
                                    >
                                        <v-col
                                            cols="12"
                                            class="hb-forms-content"
                                        >
                                            <span v-if="contact && ( contact.driver_license || contact.driver_license_exp || contact.driver_license_state || contact.driver_license_country )">
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" class="ma-0 pa-0">
                                                        <div v-if="contact.driver_license">{{ contact.driver_license }}</div>
                                                        <div>
                                                            <span v-if="contact.driver_license_exp">{{ contact.driver_license_exp | formatDateServices }}</span>
                                                            <span v-if="contact.driver_license_state" :class="{'ml-2' : contact.driver_license_exp}">{{ contact.driver_license_state }},</span>
                                                            <span v-if="contact.driver_license_country" :class="{'ml-2' :  contact.driver_license_exp || contact.driver_license_state}">{{ contact.driver_license_country }}</span>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="1" class="ma-0 pa-0" align="right">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-hover v-slot:default="{ hover }">
                                                                    <span v-on="on">
                                                                        <hb-icon v-if="hovering.general_license || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                    </span>
                                                                </v-hover>
                                                            </template>
                                                            <span>Edit</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </span>
                                            <span v-else>
                                                <hb-link @click="edit !== 'general_license' ? editRow('general_license') : ''">Click to Add</hb-link>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </template>
                    </hb-expansion-panel>

                    <!-- =================================== -->
                        <!-- General Information Ends -->
                    <!-- =================================== -->

                    <!-- =================================== -->
                            <!-- Addresses Start -->
                    <!-- =================================== -->

                    <hb-expansion-panel v-if="!nationalAccount" v-show="isAdmin" :readonly="disableAddressesPanel" :expand-icon-off="form.Addresses.length === 0 || form.Addresses.length !== contact.Addresses.length">
                        <template v-slot:title>
                            Addresses
                        </template>

                        <template v-slot:actions v-if="form.Addresses.length === 0">
                            <div class="mr-n2">
                                <hb-link class="hb-default-font-size" @click="addAddress(0)">Click to Add</hb-link>
                            </div>
                        </template>

                        <template v-slot:content v-if="contact && contact.Addresses">

                            <v-expansion-panels flat v-model="addressesSubpanel" multiple class="hb-expansion-panel">
                                <v-expansion-panel class="ma-0 pa-0" v-for="(address, i) in form.Addresses" :key="i" :readonly="edit === 'addresses' + i">
                                    <v-expansion-panel-header class="hb-table-label py-0 my-0 px-4 hb-subpanel-header">
                                        <v-col cols="8" class="pa-0 ma-0">
                                            <span class="hb-default-font-size font-weight-medium" v-if="i === 0">Main Address</span>
                                            <span class="hb-default-font-size font-weight-medium" v-else>Alternate Address {{i}}</span>
                                        </v-col>
                                        <template v-slot:actions>
                                            <div class="d-flex align-center mr-3" v-if="i !== 0 && i < contact.Addresses.length && contact.Addresses.length === form.Addresses.length">
                                                <hb-link @click="removeAddress(i)" class="hb-default-font-size">Remove</hb-link>
                                            </div>
                                            <div class="d-flex align-center" v-if="i >= contact.Addresses.length && contact.Addresses.length !== form.Addresses.length">
                                                <hb-link @click="clearAllEditing()" class="hb-default-font-size">Cancel</hb-link>
                                            </div>
                                            <v-icon v-if="edit !== 'addresses' + i">$expand</v-icon>
                                        </template>

                                        <hb-modal v-model="confirmRemoveAddress" show-help-link v-if="confirmRemoveAddress" size="medium" title="Delete Address" confirmation>
                                            <template v-slot:content>
                                                <div class="py-4 px-6">
                                                    Are you sure you want to delete the selected address?
                                                    <br /><br />
                                                    This action cannot be undone.
                                                </div>
                                            </template>
                                            <template v-slot:actions>
                                                <hb-btn color="destructive" @click="deleteAddress(selectedAddress)">Delete</hb-btn>
                                            </template>
                                        </hb-modal>
                                    </v-expansion-panel-header>
                                    <v-divider></v-divider>
                                    <v-expansion-panel-content class="pa-0 ma-0" elevation="0">
                                        <v-row class="hb-table-row ma-0 pa-0">
                                            <v-col class="hb-forms-label" cols="4">
                                                Address
                                            </v-col>
                                            <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'addresses_address' + i && edit !== 'addresses' + i}">
                                                <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'addresses' + i || edit === 'addresses_address' + i">
                                                    <v-col cols="12" class="ma-0 pa-0">
                                                        <v-row class="ma-0 pa-0">
                                                            <v-col cols="12" class="hb-z-index-99999 ma-0 pa-0 hb-forms-max-width-498">
                                                                <HbAutocomplete
                                                                    :items="countriesListMixin"
                                                                    :hide-details="!errors.collect('addresses.address_country_' + i).length"
                                                                    v-validate="'required'"
                                                                    v-model="address.Address.country"
                                                                    label="Country"
                                                                    single-line
                                                                    :id="'address_country_' + i"
                                                                    data-vv-scope="addresses"
                                                                    data-vv-as="country"
                                                                    :name="'address_country_' + i"
                                                                    :error-messages="errors.collect('addresses.address_country_' + i)"
                                                                    @change="setCountry(i)"
                                                                >
                                                                    <template v-slot:selection="data">
                                                                        {{data.item}}
                                                                    </template>
                                                                    <template v-slot:item="data">
                                                                        {{data.item}}
                                                                    </template>
                                                                </HbAutocomplete>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="ma-0 pa-0 mt-3">
                                                            <v-col cols="12" class="hb-z-index-99999 ma-0 pa-0 hb-forms-max-width-498">
                                                                <HbSelect
                                                                    :items="location_types"
                                                                    :hide-details="!errors.collect('addresses.address_type_' + i).length"
                                                                    v-validate="'required'"
                                                                    v-model="address.type"
                                                                    label="Type"
                                                                    single-line
                                                                    :id="'address_type_' + i"
                                                                    data-vv-scope="addresses"
                                                                    data-vv-as="address type"
                                                                    :name="'address_type_' + i"
                                                                    :error-messages="errors.collect('addresses.address_type_' + i)"
                                                                ></HbSelect>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="ma-0 pa-0 mt-3">
                                                            <v-col cols="12" class="ma-0 pa-0">
                                                                <HbTextField
                                                                    :id="'address_address_' + i"
                                                                    :name="'address_address_' + i"
                                                                    v-validate="'required|max:45'"
                                                                    v-model.lazy="address.Address.address"
                                                                    :hide-details="!errors.collect('addresses.address_address_' + i).length"
                                                                    label="Street"
                                                                    single-line
                                                                    data-vv-as="Street"
                                                                    data-vv-scope="addresses"
                                                                    :error-messages="errors.collect('addresses.address_address_' + i)"
                                                                ></HbTextField>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="ma-0 pa-0 mt-3">
                                                            <v-col cols="12" class="ma-0 pa-0">
                                                                <HbTextField
                                                                    :id="'address_address2_' + i"
                                                                    :name="'address_address2_' + i"
                                                                    v-model.lazy="address.Address.address2"
                                                                    v-validate="'max:45'"
                                                                    :hide-details="!errors.collect('addresses.address_address2_' + i).length"
                                                                    label="Suite / Apt"
                                                                    single-line
                                                                    data-vv-as="Suite / Apt"
                                                                    data-vv-scope="addresses"
                                                                    :error-messages="errors.collect('addresses.address_address2_' + i)"
                                                                ></HbTextField>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="ma-0 pa-0 mt-3">
                                                            <v-col cols="3" class="ma-0 pa-0 pr-3">
                                                                <HbTextField
                                                                    :id="'address_zip_' + i"
                                                                    :name="'address_zip_' + i"
                                                                    v-validate="'required'"
                                                                    v-model.lazy="address.Address.zip"
                                                                    :hide-details="!errors.collect('addresses.address_zip_' + i).length"
                                                                    label="Zip"
                                                                    single-line
                                                                    data-vv-as="zip"
                                                                    data-vv-scope="addresses"
                                                                    :error-messages="errors.collect('addresses.address_zip_' + i)"
                                                                ></HbTextField>
                                                            </v-col>
                                                            <v-col cols="4" class="hb-z-index-99999 ma-0 pa-0 pl-3">
                                                                <HbAutocomplete
                                                                    :items="stateListMixin[i] || []"
                                                                    :hide-details="!errors.collect('addresses.address_state_' + i).length"
                                                                    v-validate="stateListMixin[i] && stateListMixin[i].length ? 'required' : ''"
                                                                    v-model="address.Address.state"
                                                                    item-text='name'
                                                                    item-value='value'
                                                                    label="State"
                                                                    single-line
                                                                    :id="'address_state_' + i"
                                                                    data-vv-scope="addresses"
                                                                    data-vv-as="state"
                                                                    :name="'address_state_' + i"
                                                                    :error-messages="errors.collect('addresses.address_state_' + i)"
                                                                    @change="setState(i)"
                                                                >
                                                                </HbAutocomplete>
                                                            </v-col>
                                                            <v-col cols="5" class="hb-z-index-99999 ma-0 pa-0 pl-3">
                                                                <HbAutocomplete
                                                                    :items="cityListMixin[i] || []"
                                                                    :hide-details="!errors.collect('addresses.address_city_' + i).length"
                                                                    v-validate="cityListMixin[i] && cityListMixin[i].length ? 'required' : ''"
                                                                    v-model="address.Address.city"
                                                                    label="City"
                                                                    single-line
                                                                    :id="'address_city_' + i"
                                                                    data-vv-scope="addresses"
                                                                    data-vv-as="city"
                                                                    :name="'address_city_' + i"
                                                                    :data-vv-name="'address_city_' + i"
                                                                    :error-messages="errors.collect('addresses.address_city_' + i)"
                                                                    @keydown.enter="setCityList({event: $event, country: address.Address.country, state: address.Address.state, cityList: cityListMixin, index: i, toValidate: true})"
                                                                >
                                                                </HbAutocomplete>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0 ma-0 mt-3 d-flex align-center" v-if="edit === 'addresses_address' + i">
                                                        <hb-btn color="primary" @click="save('addresses',null, i)">Save</hb-btn>
                                                        <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    v-else
                                                    class="ma-0 pa-0"
                                                    @mouseover="hoverRevert(); hovering.addresses_address = true, current_row_hovered = 'addresses_address' + i"
                                                    @mouseleave="hovering.addresses_address = false, current_row_hovered = ''"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="hb-forms-content"
                                                        @click="edit !== 'addresses_address' + i ? editRow('addresses_address' + i) : ''"
                                                    >
                                                        <div v-if="address.type || address.Address.address || address.Address.address2 || address.Address.city || address.Address.state || address.Address.zip">
                                                            <v-row class="ma-0 pa-0">
                                                                <v-col cols="11" class="ma-0 pa-0">
                                                                    <div class="pa-0 ma-0">
                                                                        <div v-show="address.type">{{address.type}}</div>
                                                                        <div v-show="address.Address.address || address.Address.address2">
                                                                            <span v-if="address.Address.address"> {{address.Address.address}}</span>
                                                                            <span v-if="address.Address.address2"> {{address.Address.address2}}</span>,
                                                                            {{  address.Address | formatAddress }}
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="1" class="ma-0 pa-0" align="right">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-hover v-slot:default="{ hover }">
                                                                                <span v-on="on">
                                                                                    <hb-icon v-if="(hovering.addresses_address && current_row_hovered === 'addresses_address' + i) || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                                </span>
                                                                            </v-hover>
                                                                        </template>
                                                                        <span>Edit</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                        <div v-else>
                                                            <hb-link @click="addAddress(0)">Click to Add</hb-link>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col> 
                                            <v-row class="ma-0 pa-0 hb-default-font-size" v-if="contact.Addresses.length < i + 1">
                                                <v-col cols="12" class="pa-0 ma-0 d-flex align-center justify-end">
                                                    <v-divider class="ma-0 pa-0"></v-divider>
                                                </v-col>
                                                <v-col cols="12" class="pa-0 ma-0 my-2 d-flex align-center justify-end">
                                                    <hb-link class="mr-2" @click="clearAllEditing()">Cancel</hb-link>
                                                    <hb-btn color="primary" class="mr-3" @click="save('addresses')" :disabled="!address.type && !address.Address.address && !address.Address.address2 && !address.Address.city && !address.Address.state && !address.Address.zip && !address.move_in && !address.move_out && !address.rent && !address.landlord && !address.phone">Save</hb-btn>
                                                </v-col>
                                            </v-row>
                                        </v-row>
                                        <v-divider class="ma-0 pa-0" v-if="i < contact.Addresses.length" style="border: 1px;"></v-divider>
                                        <v-divider v-if="contact.Addresses.length === form.Addresses.length" class="ma-0 pa-0" style="border: 0px solid #101318"></v-divider>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-row class="ma-0 pa-0 my-2 mr-3 hb-default-font-size" v-if="(contact.Addresses.length || form.Addresses.length) && contact.Addresses.length === form.Addresses.length">
                                <v-col cols="12" class="ma-0 pa-0 py-2 d-flex align-center justify-end">
                                    <hb-link @click="addAddress(contact.Addresses.length)">+ Add New Address</hb-link>
                                </v-col>
                            </v-row>
                        </template>
                    </hb-expansion-panel>

                    <!-- =================================== -->
                            <!-- Addresses Ends -->
                    <!-- =================================== -->

                    <!-- =================================== -->
                        <!-- Additional Contacts Start -->
                    <!-- =================================== -->

                    <hb-expansion-panel v-show="isAdmin" :readonly="disableContactsPanel" :expand-icon-off="form.Relationships.length === 0 || form.Relationships.length !== contactCopy.Relationships.length">
                        <template v-slot:title>
                            Additional Contacts
                        </template>
                        <template v-slot:actions>
                            <div class="mr-n2" v-if="!form.Relationships.length">
                                <hb-link class="hb-default-font-size" @click="addRelationship(0)">Click to Add</hb-link>
                            </div>
                        </template>
                        
                        <template v-slot:content v-if="contact && contactCopy.Relationships">
                            <v-expansion-panels flat v-model="contactsSubpanel" multiple class="hb-expansion-panel">
                                <v-expansion-panel class="ma-0 pa-0" v-show="isAdmin" v-for="(r, i) in form.Relationships" :key="i" :readonly="edit === 'contacts' + i">
                                    <v-expansion-panel-header class="hb-table-label py-3 my-0 px-4 hb-subpanel-header">
                                        <v-col cols="8" class="pa-0 ma-0">
                                            <span class="hb-default-font-size font-weight-medium" v-if="r.Contact.first || r.Contact.last">{{r.Contact.first}} {{r.Contact.last}}</span>
                                            <span class="hb-default-font-size font-weight-medium" v-else>New Contact</span>
                                        </v-col>
                                        <template v-slot:actions>
                                            <div class="d-flex align-center mr-3" v-if="i < contactCopy.Relationships.length && contactCopy.Relationships.length === form.Relationships.length">
                                                <hb-link @click="removeContact(i)" class="hb-default-font-size">Remove</hb-link>
                                            </div>
                                            <div class="d-flex align-center" v-if="i >= contactCopy.Relationships.length && contactCopy.Relationships.length !== form.Relationships.length">
                                                <hb-link @click="clearAllEditing()" class="hb-default-font-size mr-2">Cancel</hb-link>
                                                <hb-btn color="primary" @click="saveAdditionalContact('contacts', 'phone', i)" :disabled="!r.is_emergency && !r.is_alternate && !r.is_cosigner && !r.is_authorized && !r.is_lien_holder && !r.Contact.first && !r.Contact.last && !r.Contact.email && !r.Contact.Phones[0].type && !r.Contact.Phones[0].phone && !r.Contact.Phones[0].sms && !r.Contact.Addresses[0].type && !r.Contact.Addresses[0].Address.address && !r.Contact.Addresses[0].Address.address2 && !r.Contact.Addresses[0].Address.zip && !r.Contact.Addresses[0].Address.state && !r.Contact.Addresses[0].Address.city">Save</hb-btn>
                                            </div>
                                            <v-icon v-if="edit !== 'contacts' + i">$expand</v-icon>
                                        </template>

                                        <hb-modal v-model="confirmRemoveContact" show-help-link v-if="confirmRemoveContact" size="medium" title="Delete Additional Contact" confirmation>
                                            <template v-slot:content>
                                                <div class="py-4 px-6">
                                                    Are you sure you want to delete the selected additional contact?
                                                    <br /><br />
                                                    This action cannot be undone.
                                                </div>
                                            </template>
                                            <template v-slot:actions>
                                                <hb-btn color="destructive" @click="deleteRelationship(selectedContact)">Delete</hb-btn>
                                            </template>
                                        </hb-modal>
                                    </v-expansion-panel-header>
                                    <v-divider></v-divider>
                                    <v-expansion-panel-content class="pa-0 ma-0" elevation="0">

                                        <v-row class="hb-table-row ma-0 pa-0">
                                           <v-col class="hb-forms-label" cols="4">
                                                Space and Designation*
                                            </v-col>
                                            <v-col cols="8" class="ma-0">
                                                <div class="space-designation-col">
                                                    <div class="pb-1" v-for="(relation,i) in r.lease_data" :key="i">
                                                        <span class="hb-text-night">{{ relation.unit_number }}: </span><span class="text-body-2 hb-text-night-light">{{ relation.designations.map(item => item.name).join(',') }}</span>
                                                    </div>
                                                    <div class="edit-icon-contact">
                                                        <hb-icon small @click="showEditDesignationEvent({ index: i })">mdi-pencil</hb-icon>
                                                    </div>
                                                </div>
                                                <v-col class="pa-0 pt-1" v-if="activeEdit.includes(i)">
                                                    <v-expansion-panels v-model="spaceDesignationPanel" multiple>
                                                        <hb-expansion-panel>
                                                            <template v-slot:title>
                                                                <span class="hb-font-body-medium">Multiple lease change</span>
                                                            </template>
                                                            <template v-slot:content>
                                                        <hb-combobox
                                                            class="pa-3"
                                                            v-model="r.selected_leases_list"
                                                            :items="comboLeaseOptions"
                                                            :id="'associated_space_'+i"
                                                            :name="'associated_space_'+i"
                                                            v-validate.disable="{required: containsIsAlternate(i), max: 45}"
                                                            select 
                                                            multiple 
                                                            item-text="name"
                                                            item-value="id"
                                                            label="Select associated space(s)"
                                                            data-vv-scope="contacts"
                                                            data-vv-as="Associated space(s)"
                                                            :error="errors.collect('contacts.associated_space_'+i).length > 0"
                                                            return-object>
                                                                <template v-slot:item="{item, attrs}">
                                                                    <v-list-item-action> 
                                                                        <v-icon v-if="attrs.inputValue">check_box</v-icon> 
                                                                        <v-icon v-else :color="item.disabled ? '#DFE3E8' : '#9b9b9b'">check_box_outline_blank</v-icon>
                                                                    </v-list-item-action>
                                                                    <v-list-item-content> 
                                                                        <v-list-item-title> 
                                                                            <span v-if="item.name" :class="{'text--disabled': item.disabled}">{{ item.name }}</span>
                                                                        </v-list-item-title> 
                                                                    </v-list-item-content> 
                                                            </template>
                                                        </hb-combobox>
                                                    
                                                                <p class="my-1 pl-3 text--secondary">In order to change for single lease, please do it from lease widget</p>
                                                                <hb-combobox
                                                                class="pa-3"
                                                                v-model="r.selected_designations_list"
                                                                :items="nationalAccount ? designatedAsTypesForNa : designatedAsTypes"
                                                                :id="'designations_'+i"
                                                                :name="'designations_'+i"
                                                                :showSelectAll="false"
                                                                item-text="name"
                                                                item-value="id"
                                                                return-object
                                                                label="Select contact type"
                                                                v-validate="'required'"
                                                                data-vv-scope="contacts"
                                                                data-vv-as="Designation Type"
                                                                :error="errors.collect('contacts.designations_'+i).length > 0"
                                                                 >
                                                                <template v-slot:item="{item, attrs}">
                                                                    <v-list-item-action> 
                                                                        <v-icon v-if="attrs.inputValue">check_box</v-icon> 
                                                                        <v-icon v-else :color="item.disabled ? '#DFE3E8' : '#9b9b9b'">check_box_outline_blank</v-icon>
                                                                    </v-list-item-action>
                                                                    <v-list-item-content> 
                                                                        <v-list-item-title> 
                                                                            <span v-if="item.name" :class="{'text--disabled': item.disabled}">{{ item.name }}</span>
                                                                        </v-list-item-title> 
                                                                    </v-list-item-content> 
                                                            </template>
                                                            </hb-combobox>

                                                        <hb-bottom-action-bar v-if="r.related_contact_id" @close="closeEditEvent(i)">
                                                            <template v-slot:right-actions>
                                                                <hb-btn :disabled="isSavingLoad" :loading="isSavingLoad" @click="saveAdditionalContact('contacts', 'designation', i, r)">Save</hb-btn>
                                                            </template>
                                                        </hb-bottom-action-bar>
                                                            </template>
                                                        </hb-expansion-panel>
                                                    </v-expansion-panels>
                                                    </v-col>
                                                    </v-col>
                                                </v-row>

                                        <v-row class="hb-table-row ma-0 pa-0">
                                            <v-col class="hb-forms-label" cols="4">
                                                Name
                                            </v-col>
                                            <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'contacts_name' + i && edit !== 'contacts' + i}">
                                                <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'contacts' + i || edit === 'contacts_name' + i">
                                                    <v-col cols="6" class="ma-0 pa-0 pr-3">
                                                        <HbTextField
                                                            :id="'relationship_first_' + i"
                                                            :name="'relationship_first_' + i"
                                                            v-model="r.Contact.first"
                                                            v-validate="'required|max:45'"
                                                            data-vv-as="first name"
                                                            label="First Name"
                                                            single-line
                                                            data-vv-scope="contacts"
                                                            :hide-details="!errors.collect('contacts.relationship_first_' + i).length"
                                                            :error-messages="errors.collect('contacts.relationship_first_' + i)"
                                                            :autofocus="edit === 'contacts_name' + i"
                                                        ></HbTextField>
                                                    </v-col>
                                                    <v-col cols="6" class="ma-0 pa-0">
                                                        <HbTextField
                                                            :id="'relationship_last_' + i"
                                                            :name="'relationship_last_' + i"
                                                            v-model="r.Contact.last"
                                                            v-validate="'required|max:45'"
                                                            data-vv-as="last name"
                                                            label="Last Name"
                                                            single-line
                                                            data-vv-scope="contacts"
                                                            :hide-details="!errors.collect('contacts.relationship_last_' + i).length"
                                                            :error-messages="errors.collect('contacts.relationship_last_' + i)"
                                                        ></HbTextField>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0 ma-0 mt-5 d-flex align-center" v-if="edit === 'contacts_name' + i">
                                                        <hb-btn color="primary" @click="saveAdditionalContact('contacts', 'name', i, r)" :disabled="contactCopy.Relationships[i] && contactCopy.Relationships[i].Contact.first === r.Contact.first && contactCopy.Relationships[i].Contact.last === r.Contact.last">Save</hb-btn>
                                                        <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    v-else
                                                    class="ma-0 pa-0"
                                                    @mouseover="hoverRevert(); hovering.contacts_name = true, current_row_hovered = 'contacts_name' + i"
                                                    @mouseleave="hovering.contacts_name = false, current_row_hovered = ''"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="hb-forms-content"
                                                        @click="edit !== 'contacts_name' + i ? editRow('contacts_name' + i) : ''"
                                                    >
                                                        <div v-if="r.Contact.first || r.Contact.last">
                                                            <v-row class="ma-0 pa-0">
                                                                <v-col cols="11" class="ma-0 pa-0">
                                                                    {{r.Contact.first}} {{r.Contact.last}}
                                                                </v-col>
                                                                <v-col cols="1" class="ma-0 pa-0" align="right">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-hover v-slot:default="{ hover }">
                                                                                <span v-on="on">
                                                                                    <hb-icon v-if="(hovering.contacts_name && current_row_hovered === 'contacts_name' + i) || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                                </span>
                                                                            </v-hover>
                                                                        </template>
                                                                        <span>Edit</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                        <div v-else>
                                                            <hb-link @click="edit !== 'contacts_name' + i ? editRow('contacts_name' + i) : ''">Click to Add</hb-link>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="hb-table-row ma-0 pa-0">
                                            <v-col class="hb-forms-label" cols="4">
                                                Email
                                            </v-col>
                                            <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'contacts_email' + i && edit !== 'contacts' + i}">
                                                <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'contacts' + i || edit === 'contacts_email' + i">
                                                    <v-col cols="12" class="ma-0 pa-0">
                                                        <HbTextField
                                                            :id="'relationship_email_' + i"
                                                            :name="'relationship_email_' + i"
                                                            v-model="r.Contact.email"
                                                            v-validate="{ max:100, required: isEmailRequired(i) ? true : false, email: true }"
                                                            data-vv-as="relationship email"
                                                            data-vv-scope="contacts"
                                                            label="Email"
                                                            single-line
                                                            :autofocus="edit === 'contacts_email' + i"
                                                            :hide-details="!errors.collect('contacts.relationship_email_' + i).length"
                                                            :error-messages="errors.collect('contacts.relationship_email_' + i)"
                                                        ></HbTextField>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0 ma-0 mt-5 d-flex align-center" v-if="edit === 'contacts_email' + i">
                                                        <hb-btn color="primary" @click="saveAdditionalContact('contacts', 'email', i, r)" :disabled="contactCopy.Relationships[i] && contactCopy.Relationships[i].Contact.email === r.Contact.email">Save</hb-btn>
                                                        <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    v-else
                                                    class="ma-0 pa-0"
                                                    @mouseover="hoverRevert(); hovering.contacts_email = true, current_row_hovered = 'contacts_email' + i"
                                                    @mouseleave="hovering.contacts_email = false, current_row_hovered = ''"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="hb-forms-content"
                                                        @click="edit !== 'contacts_email' + i ? editRow('contacts_email' + i) : ''"
                                                    >
                                                        <div v-if="r.Contact.email">
                                                            <v-row class="ma-0 pa-0">
                                                                <v-col cols="11" class="ma-0 pa-0 hb-text-overflow-wrap">
                                                                    {{r.Contact.email}}
                                                                </v-col>
                                                                <v-col cols="1" class="ma-0 pa-0" align="right">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-hover v-slot:default="{ hover }">
                                                                                <span v-on="on">
                                                                                    <hb-icon v-if="(hovering.contacts_email && current_row_hovered === 'contacts_email' + i) || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                                </span>
                                                                            </v-hover>
                                                                        </template>
                                                                        <span>Edit</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                        <div v-else>
                                                            <hb-link @click="edit !== 'contacts_email' + i ? editRow('contacts_email' + i) : ''">Click to Add</hb-link>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="hb-table-row ma-0 pa-0">
                                            <v-col class="hb-forms-label" cols="4">
                                                Phone
                                            </v-col>
                                            <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'contacts_phone' + i && edit !== 'contacts' + i}">
                                                <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'contacts' + i || edit === 'contacts_phone' + i">
                                                    <v-col cols="12" class="ma-0 pa-0">
                                                        <v-row class="ma-0 pa-0" v-for="(secondaryphone, index) in r.Contact.Phones" :key="index">
                                                            <v-col cols="12" class="hb-z-index-99999 ma-0 pa-0 hb-forms-max-width-498">
                                                                <HbSelect
                                                                    :items="phone_types"
                                                                    v-validate="'required|max:45'"
                                                                    v-model="secondaryphone.type"
                                                                    label="Type"
                                                                    single-line
                                                                    :id="'relationship_phone_type_' + index"
                                                                    :name="'relationship_phone_type_' + index"
                                                                    data-vv-as="alternate contact phone type"
                                                                    data-vv-scope="contacts"
                                                                    :hide-details="!errors.collect('contacts.relationship_phone_type_' + index).length"
                                                                    :error-messages="errors.collect('contacts.relationship_phone_type_' + index)"
                                                                ></HbSelect>
                                                            </v-col>
                                                            <v-col cols="3" class="ma-0 pa-0 mt-3 pr-3">
                                                                <HbAutocomplete
                                                                    :items="countryCodesList"
                                                                    :hide-details="!errors.collect('contacts.phone_code_' + i).length"
                                                                    v-validate="'required'"
                                                                    :clearable="false"
                                                                    v-model="contactPhoneCode"
                                                                    label="Code"
                                                                    single-line
                                                                    :id="'relationship_phone_code_' + i"
                                                                    data-vv-scope="contacts"
                                                                    data-vv-as="phone code"
                                                                    :name="'relationship_phone_code_' + i"
                                                                    :error-messages="errors.collect('contacts.phone_code_' + i)"
                                                                    @change="checkValidPhone(contactPhoneCode, contactPhoneNumber)"
                                                                    @focusout="checkValidPhone(contactPhoneCode, contactPhoneNumber)"
                                                                >
                                                                    <template v-slot:selection="data">
                                                                        +{{data.item}}
                                                                    </template>
                                                                    <template v-slot:item="data">
                                                                        +{{data.item}}
                                                                    </template>
                                                                </HbAutocomplete>
                                                            </v-col>
                                                            <v-col cols="5" class="ma-0 pa-0 mt-3 pr-3">
                                                                <HbTextField
                                                                    v-validate="'required|max:45|numeric'"
                                                                    v-model="contactPhoneNumber"
                                                                    label="Number"
                                                                    single-line
                                                                    :id="'relationship_phone_' + i"
                                                                    :name="'relationship_phone_' + i"
                                                                    data-vv-scope="contacts"
                                                                    data-vv-as="alternate contact phone"
                                                                    :hide-details="!errors.collect('contacts.relationship_phone_' + i).length && checkValidPhone(contactPhoneCode, contactPhoneNumber)"
                                                                    :error-messages="errors.collect('contacts.relationship_phone_' + i).length ? errors.collect('contacts.relationship_phone_' + i) : !checkValidPhone(contactPhoneCode, contactPhoneNumber) ? 'Please Enter a Valid Phone Number' : null"
                                                                ></HbTextField>
                                                            </v-col>
                                                            <v-col cols="3" class="ma-0 pa-0 mt-3">
                                                                <hb-checkbox
                                                                    :id="'relationship_sms_' + i"
                                                                    :name="'relationship_sms_' + i"
                                                                    :data-name="'relationship_sms_' + i"
                                                                    v-model="secondaryphone.sms"
                                                                    hide-details
                                                                >
                                                                    <template v-slot:label>
                                                                        <span class="text-capitalize">SMS</span>
                                                                    </template>
                                                                </hb-checkbox>
                                                            </v-col>
                                                            <v-col cols="12" class="pa-0 ma-0 mt-3 d-flex align-center" v-if="edit === 'contacts_phone' + i">
                                                                <hb-btn color="primary" @click="saveAdditionalContact('contacts', 'phone', i, r)">Save</hb-btn>
                                                                <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    v-else
                                                    class="ma-0 pa-0"
                                                    @mouseover="hoverRevert(); hovering.contacts_phone = true, current_row_hovered = 'contacts_phone' + i"
                                                    @mouseleave="hovering.contacts_phone = false, current_row_hovered = ''"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="hb-forms-content"
                                                        @click="edit !== 'contacts_phone' + i ? editRow('contacts_phone' + i,i) : ''"
                                                    >
                                                        <div v-if="r.Contact.Phones.length">
                                                            <v-row class="ma-0 pa-0">
                                                                <v-col cols="11" class="ma-0 pa-0">
                                                                    <div v-for="(secondaryphone, index) in r.Contact.Phones" :key="index">
                                                                        <span class="pr-2">{{secondaryphone.type}}</span>{{secondaryphone.phone | formatPhone}}
                                                                        <span v-if="secondaryphone.sms" class="pl-1 hb-text-light">Can SMS</span>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="1" class="ma-0 pa-0" align="right">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-hover v-slot:default="{ hover }">
                                                                                <span v-on="on">
                                                                                    <hb-icon v-if="(hovering.contacts_phone && current_row_hovered === 'contacts_phone' + i) || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                                </span>
                                                                            </v-hover>
                                                                        </template>
                                                                        <span>Edit</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                        <div v-else>
                                                            <hb-link @click="edit !== 'contacts_phone' + i ? editRow('contacts_phone' + i,i) : ''">Click to Add</hb-link>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="hb-table-row ma-0 pa-0">
                                             <v-col class="hb-forms-label" cols="4">
                                                Address
                                            </v-col>
                                            <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'contacts_address' + i && edit !== 'contacts' + i}">
                                                <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'contacts' + i || edit === 'contacts_address' + i">
                                                    <v-col cols="12" class="ma-0 pa-0">
                                                        <v-row class="ma-0 pa-0">
                                                            <v-col cols="12" class="hb-z-index-99999 ma-0 pa-0 mt-3">
                                                                <HbAutocomplete
                                                                    :items="countriesListMixin"
                                                                    v-validate="{required: (containsIsAlternate(i))}"
                                                                    v-model="r.Contact.Addresses[0].Address.country"
                                                                    label="Country"
                                                                    single-line
                                                                    :id="'relationship_address_country' + i"
                                                                    data-vv-scope="contacts"
                                                                    data-vv-as="Country"
                                                                    :name="'relationship_address_country_' + i"
                                                                    :error="errors.collect('contacts.relationship_address_country' + i).length > 0"
                                                                    @change="setRelationCountry(i)"
                                                                >
                                                                </HbAutocomplete>
                                                            </v-col>
                                                            <v-col cols="12" class="hb-z-index-99999 hb-forms-max-width-498 ma-0 pa-0 mt-3">
                                                                <HbSelect
                                                                    :items="location_types"
                                                                    v-validate="{required: containsIsAlternate(i), max: 45}"
                                                                    v-model="r.Contact.Addresses[0].type"
                                                                    label="Type"
                                                                    single-line
                                                                    :id="'relationship_address_type_' + i"
                                                                    :name="'relationship_address_type_' + i"
                                                                    data-vv-as="address type"
                                                                    data-vv-scope="contacts"
                                                                    :hide-details="!errors.collect('contacts.relationship_address_type_' + i).length"
                                                                    :error-messages="errors.collect('contacts.relationship_address_type_' + i)"
                                                                    :clearable="true"
                                                                    @click:clear="r.Contact.Addresses[0].type = ''"
                                                                ></HbSelect>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="ma-0 pa-0 mt-3">
                                                            <v-col cols="12" class="ma-0 pa-0">
                                                                <HbTextField
                                                                    :id="'relationship_address_address_' + i"
                                                                    :name="'relationship_address_address_' + i"
                                                                    v-validate="{required: containsIsAlternate(i), max: 45}"
                                                                    v-model="r.Contact.Addresses[0].Address.address"
                                                                    label="Street"
                                                                    single-line
                                                                    data-vv-as="Street"
                                                                    data-vv-scope="contacts"
                                                                    :hide-details="!errors.collect('contacts.relationship_address_address_' + i).length"
                                                                    :error-messages="errors.collect('contacts.relationship_address_address_' + i)"
                                                                ></HbTextField>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="ma-0 pa-0 mt-3">
                                                            <v-col cols="12" class="ma-0 pa-0">
                                                                <HbTextField
                                                                    :id="'relationship_address_address2_' + i"
                                                                    :name="'relationship_address_address2_' + i"
                                                                    v-model="r.Contact.Addresses[0].Address.address2"
                                                                    v-validate="'max:45'"
                                                                    label="Suite / Apt"
                                                                    single-line
                                                                    data-vv-as="Suite / Apt"
                                                                    data-vv-scope="contacts"
                                                                    :hide-details="!errors.collect('contacts.relationship_address_address2_' + i).length"
                                                                    :error-messages="errors.collect('contacts.relationship_address_address2_' + i)"
                                                                ></HbTextField>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="ma-0 pa-0 mt-3">
                                                            <v-col cols="3" class="ma-0 pa-0 pr-3">
                                                                <HbTextField
                                                                    :id="'relationship_address_zip_' + i"
                                                                    :name="'relationship_address_zip_' + i"
                                                                    v-validate="{required: containsIsAlternate(i), max: 45}"
                                                                    v-model="r.Contact.Addresses[0].Address.zip"
                                                                    label="Zip"
                                                                    single-line
                                                                    data-vv-as="zip"
                                                                    data-vv-scope="contacts"
                                                                    :hide-details="!errors.collect('contacts.relationship_address_zip_' + i).length"
                                                                    :error-messages="errors.collect('contacts.relationship_address_zip_' + i)"
                                                                ></HbTextField>
                                                            </v-col>
                                                            <v-col cols="4" class="ma-0 pa-0 pr-3"> 
                                                                <HbAutocomplete
                                                                    :items="relationStateListMixin[i]"
                                                                    v-validate="{required: containsIsAlternate(i)}"
                                                                    v-model="r.Contact.Addresses[0].Address.state"
                                                                    label="State"
                                                                    item-text="name"
                                                                    item-value="value"
                                                                    :id="'relationship_address_state_' + i"
                                                                    :name="'relationship_address_state_' + i"
                                                                    single-line
                                                                    data-vv-scope="contacts"
                                                                    data-vv-name="state"
                                                                    data-vv-as="State"
                                                                    :error="errors.collect('contacts.relationship_address_state_' + i).length > 0"
                                                                    @change="setRelationState(i)"
                                                                >
                                                                </HbAutocomplete>
                                                            </v-col>
                                                            <v-col cols="5" class="ma-0 pa-0 pl-3 hb-z-index-99999">
                                                                <HbAutocomplete
                                                                    :items="relationCityListMixin[i]"
                                                                    v-validate="{required: containsIsAlternate(i)}"
                                                                    v-model="r.Contact.Addresses[0].Address.city"
                                                                    label="City"
                                                                    single-line
                                                                    :id="'relationship_address_city_' + i"
                                                                    :name="'relationship_address_city_' + i"
                                                                    data-vv-scope="contacts"
                                                                    :data-vv-name="'relationship_address_city_' + i"
                                                                    data-vv-as="city"
                                                                    :error="errors.collect('contacts.relationship_address_city_' + i).length > 0"
                                                                    @keydown.enter="setCityList({event: $event, country: r.Contact.Addresses[0].Address.country, state: r.Contact.Addresses[0].Address.state, cityList: relationCityListMixin, index: i, toValidate: true})"
                                                                >
                                                                </HbAutocomplete>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0 ma-0 mt-3 d-flex align-center" v-if="edit === 'contacts_address' + i">
                                                        <hb-btn color="primary" @click="saveAdditionalContact('contacts','address', i, r)" :disabled="contactCopy.Relationships[i] && contactCopy.Relationships[i].Contact.Addresses && contactCopy.Relationships[i].Contact.Addresses[0] && contactCopy.Relationships[i].Contact.Addresses[0].type === r.Contact.Addresses[0].type && contactCopy.Relationships[i].Contact.Addresses[0].Address.address === r.Contact.Addresses[0].Address.address && contactCopy.Relationships[i].Contact.Addresses[0].Address.address2 === r.Contact.Addresses[0].Address.address2 && contactCopy.Relationships[i].Contact.Addresses[0].Address.zip === r.Contact.Addresses[0].Address.zip && contactCopy.Relationships[i].Contact.Addresses[0].Address.state === r.Contact.Addresses[0].Address.state && contactCopy.Relationships[i].Contact.Addresses[0].Address.city === r.Contact.Addresses[0].Address.city">Save</hb-btn>
                                                        <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    v-else
                                                    class="ma-0 pa-0"
                                                    @mouseover="hoverRevert(); hovering.contacts_address = true, current_row_hovered = 'contacts_address' + i"
                                                    @mouseleave="hovering.contacts_address = false, current_row_hovered = ''"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="hb-forms-content"
                                                        @click="edit !== 'contacts_address' + i ? editRow('contacts_address' + i) : ''"
                                                    >
                                                        <div v-if="r.Contact.Addresses[0].type || r.Contact.Addresses[0].Address.address || r.Contact.Addresses[0].Address.address2 || r.Contact.Addresses[0].Address.state || r.Contact.Addresses[0].Address.zip || r.Contact.Addresses[0].Address.city">
                                                            <v-row class="ma-0 pa-0">
                                                                <v-col cols="11" class="ma-0 pa-0">
                                                                    <div v-show="r.Contact.Addresses[0].type">{{r.Contact.Addresses[0].type}}</div>
                                                                    <div v-show="r.Contact.Addresses[0].Address.address || r.Contact.Addresses[0].Address.address2">
                                                                        {{r.Contact.Addresses[0].Address.address}}
                                                                        <span v-if="r.Contact.Addresses[0].Address.address2"> 
                                                                            {{r.Contact.Addresses[0].Address.address2}}
                                                                        </span>,
                                                                        {{ r.Contact.Addresses[0].Address | formatAddress }}
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="1" class="ma-0 pa-0" align="right">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-hover v-slot:default="{ hover }">
                                                                                <span v-on="on">
                                                                                    <hb-icon v-if="(hovering.contacts_address && current_row_hovered === 'contacts_address' + i) || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                                </span>
                                                                            </v-hover>
                                                                        </template>
                                                                        <span>Edit</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                        <div v-else>
                                                            <hb-link @click="edit !== 'contacts_address' + i ? editRow('contacts_address' + i) : ''">Click to Add</hb-link>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-row class="ma-0 pa-0 hb-default-font-size" v-if="contactCopy.Relationships.length < i + 1">
                                                <v-col cols="12" class="pa-0 ma-0 d-flex align-center justify-end">
                                                    <v-divider class="ma-0 pa-0"></v-divider>
                                                </v-col>
                                                <v-col cols="12" class="pa-0 ma-0 my-2 d-flex align-center justify-end">
                                                    <hb-link class="mr-2" @click="clearAllEditing()">Cancel</hb-link>
                                                    <hb-btn color="primary" class="mr-3" @click="saveAdditionalContact('contacts', 'phone', i, r)" :disabled="!r.is_emergency && !r.is_alternate && !r.is_cosigner && !r.is_authorized && !r.is_lien_holder && !r.Contact.first && !r.Contact.last && !r.Contact.email && !r.Contact.Phones[0].type && !r.Contact.Phones[0].phone && !r.Contact.Phones[0].sms && !r.Contact.Addresses[0].type && !r.Contact.Addresses[0].Address.address && !r.Contact.Addresses[0].Address.address2 && !r.Contact.Addresses[0].Address.zip && !r.Contact.Addresses[0].Address.state && !r.Contact.Addresses[0].Address.city">Save</hb-btn>
                                                </v-col>
                                            </v-row>
                                        </v-row>
                                        <v-divider class="ma-0 pa-0" style="border: 0px solid #101318"></v-divider>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-row class="ma-0 pa-0 my-2 mr-3 hb-default-font-size" v-if="(contactCopy.Relationships.length || form.Relationships.length) && contactCopy.Relationships.length === form.Relationships.length">
                                <v-col cols="12" class="ma-0 pa-0 py-2 d-flex align-center justify-end">
                                    <hb-link @click="addRelationship(contactCopy.Relationships.length)">+ Add New Contact</hb-link>
                                </v-col>
                            </v-row>
                        </template>
                    </hb-expansion-panel>
                    <!-- =================================== -->
                        <!-- Additional Contacts End -->
                    <!-- =================================== -->

                    <!-- =================================== -->
                        <!-- Business Representative Start -->
                    <!-- =================================== --> 
                    <hb-expansion-panel v-if="Object.keys(form.Representative).length && form.Representative.Contact.id" v-show="isAdmin" :expand-icon-off="Object.keys(form.Representative).length === 0 && form.Representative.Contact.id == null">
                        <template v-slot:title>
                             Business Representative
                        </template>
                        <template v-slot:content>
                                        <v-row class="hb-table-row ma-0 pa-0">
                                           <v-col class="hb-forms-label" cols="4">
                                                Designation
                                            </v-col>
                                            <v-col cols="8" class="ma-0">
                                                <div class="space-designation-col">
                                                    <div class="pb-1">
                                                        <span>Representative</span>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row class="hb-table-row ma-0 pa-0">
                                            <v-col class="hb-forms-label" cols="4">
                                                Name
                                            </v-col>
                                            <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'reps_name' && edit !== 'reps'}">
                                                <v-row class="mx-0 pb-0 mt-3 mb-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'reps' || edit === 'reps_name'">
                                                    <v-col cols="6" class="ma-0 pa-0 pr-3">
                                                        <HbTextField
                                                            :id="'rep_first'"
                                                            :name="'rep_first'"
                                                            v-model="form.Representative.Contact.first"
                                                            v-validate="'required|max:45'"
                                                            data-vv-as="first name"
                                                            label="First Name"
                                                            single-line
                                                            data-vv-scope="reps"
                                                            :hide-details="!errors.collect('reps.rep_first').length"
                                                            :error-messages="errors.collect('reps.rep_first')"
                                                            :autofocus="edit === 'reps_name'"
                                                        ></HbTextField>
                                                    </v-col>
                                                    <v-col cols="6" class="ma-0 pa-0">
                                                        <HbTextField
                                                            :id="'rep_last'"
                                                            :name="'rep_last'"
                                                            v-model="form.Representative.Contact.last"
                                                            v-validate="'required|max:45'"
                                                            data-vv-as="last name"
                                                            label="Last Name"
                                                            single-line
                                                            data-vv-scope="reps"
                                                            :hide-details="!errors.collect('reps.rep_last').length"
                                                            :error-messages="errors.collect('reps.rep_last')" 
                                                        ></HbTextField>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0 ma-0 mt-3 d-flex align-center" v-if="edit === 'reps_name'">
                                                        <hb-btn color="primary" @click="saveAdditionalContact('reps','name')">Save</hb-btn>
                                                        <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    v-else
                                                    class="ma-0 pa-0"
                                                    @mouseover="hoverRevert(); hovering.reps_name = true, current_row_hovered = 'reps_name'"
                                                    @mouseleave="hovering.reps_name = false, current_row_hovered = ''"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="hb-forms-content"
                                                        @click="edit !== 'reps_name' ? editRow('reps_name') : ''"
                                                    >
                                                        <div v-if="form.Representative.Contact.first || form.Representative.Contact.last">
                                                            <v-row class="ma-0 pa-0">
                                                                <v-col cols="11" class="ma-0 pa-0">
                                                                    {{form.Representative.Contact.first}} {{form.Representative.Contact.last}}
                                                                </v-col>
                                                                <v-col cols="1" class="ma-0 pa-0" align="right">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-hover v-slot:default="{ hover }">
                                                                                <span v-on="on">
                                                                                    <hb-icon v-if="(hovering.reps_name && current_row_hovered === 'reps_name') || $vuetify.breakpoint.xs" v-on="on" small :class="hover ? 'hb-text-night' : ''">mdi-pencil</hb-icon>
                                                                                </span>
                                                                            </v-hover>
                                                                        </template>
                                                                        <span>Edit</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="hb-table-row ma-0 pa-0">
                                            <v-col class="hb-forms-label" cols="4">
                                                Email
                                            </v-col>
                                            <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'reps_email' && edit !== 'reps'}">
                                                <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'reps' || edit === 'reps_email'">
                                                    <v-col cols="12" class="ma-0 pa-0">
                                                        <HbTextField
                                                            :id="'reps_email'"
                                                            :name="'reps_email'"
                                                            v-model="form.Representative.Contact.email"
                                                            v-validate="{ required: false, max:45, email: true}"
                                                            data-vv-as="representative email"
                                                            data-vv-scope="reps"
                                                            label="Email"
                                                            single-line
                                                            :autofocus="edit === 'reps_email'"
                                                            :hide-details="!errors.collect('reps.reps_email').length"
                                                            :error-messages="errors.collect('reps.reps_email')"
                                                        ></HbTextField>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0 ma-0 mt-5 d-flex align-center" v-if="edit === 'reps_email'">
                                                        <hb-btn color="primary" @click="saveAdditionalContact('reps','email')">Save</hb-btn>
                                                        <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    v-else
                                                    class="ma-0 pa-0"
                                                    @mouseover="hoverRevert(); hovering.reps_email = true, current_row_hovered = 'reps_email'"
                                                    @mouseleave="hovering.reps_email = false, current_row_hovered = ''"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="hb-forms-content"
                                                        @click="edit !== 'reps_email' ? editRow('reps_email') : ''"
                                                    >
                                                        <div v-if="form.Representative.Contact.email">
                                                            <v-row class="ma-0 pa-0">
                                                                <v-col cols="11" class="ma-0 pa-0">
                                                                    {{form.Representative.Contact.email}}
                                                                </v-col>
                                                                <v-col cols="1" class="ma-0 pa-0" align="right">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-hover v-slot:default="{ hover }">
                                                                                <span v-on="on">
                                                                                    <hb-icon v-if="(hovering.reps_email && current_row_hovered === 'reps_email') || $vuetify.breakpoint.xs" v-on="on" small :class="hover ? 'hb-text-night' : ''">mdi-pencil</hb-icon>
                                                                                </span>
                                                                            </v-hover>
                                                                        </template>
                                                                        <span>Edit</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                        <div v-else>
                                                            <hb-link  @click="edit !== 'reps_email' ? editRow('reps_email') : ''">Click to Add</hb-link>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="hb-table-row ma-0 pa-0">
                                            <v-col class="hb-forms-label" cols="4">
                                                Phone
                                            </v-col>
                                            <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'reps_phone' && edit !== 'reps'}">
                                                <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'reps' || edit === 'reps_phone'">
                                                    <v-col cols="12" class="ma-0 pa-0">
                                                        <v-row class="ma-0 pa-0" v-for="(repphone, index) in form.Representative.Contact.Phones" :key="index">
                                                            <v-col cols="12" class="hb-z-index-99999 ma-0 pa-0 hb-forms-max-width-498">
                                                                <HbSelect
                                                                    :id="'rep_phone_type' + index"
                                                                    :name="'rep_phone_type' + index"
                                                                    :items="phone_types"
                                                                    v-validate="'required|max:45'"
                                                                    v-model="repphone.type"
                                                                    label="Type"
                                                                    single-line
                                                                    data-vv-as="representative contact phone type"
                                                                    data-vv-scope="reps"
                                                                    :hide-details="!errors.collect('reps.rep_phone_type' + index).length"
                                                                    :error-messages="errors.collect('reps.rep_phone_type' + index)"
                                                                ></HbSelect>
                                                            </v-col> 
                                                            <v-col cols="3" class="ma-0 pa-0 pr-3 mt-3">
                                                                <HbAutocomplete
                                                                    :id="'rep_phone_code' + index"
                                                                    :name="'rep_phone_code' + index"
                                                                    :items="countryCodesList"
                                                                    :hide-details="!errors.collect('reps.rep_phone_code' + index).length"
                                                                    v-validate="'required'"
                                                                    :clearable="false"
                                                                    v-model="repContactPhoneCode"
                                                                    label="Code"
                                                                    single-line
                                                                    data-vv-scope="reps"
                                                                    data-vv-as="representative phone code"
                                                                    :error-messages="errors.collect('reps.rep_phone_code' + index)"
                                                                    @change="checkValidPhone(repContactPhoneCode, repContactPhoneNumber)"
                                                                    @focusout="checkValidPhone(repContactPhoneCode, repContactPhoneNumber)"
                                                                >
                                                                    <template v-slot:selection="data">
                                                                        +{{data.item}}
                                                                    </template>
                                                                    <template v-slot:item="data">
                                                                        +{{data.item}}
                                                                    </template>
                                                                </HbAutocomplete>
                                                            </v-col>
                                                            <v-col cols="5" class="ma-0 pa-0 pr-3 mt-3">
                                                                <HbTextField
                                                                    :id="'rep_phone' + index"
                                                                    :name="'rep_phone' + index"
                                                                    v-validate="'required|max:45'"
                                                                    v-mask="'(###) ### ####'"
                                                                    v-model="repContactPhoneNumber"
                                                                    label="Number"
                                                                    single-line
                                                                    data-vv-scope="reps"
                                                                    data-vv-as="representative contact phone"
                                                                    :hide-details="!errors.collect('reps.rep_phone' + index).length && checkValidPhone(repContactPhoneCode, repContactPhoneNumber)"
                                                                    :error-messages="errors.collect('reps.rep_phone' + index).length ? errors.collect('reps.rep_phone') : !checkValidPhone(repContactPhoneCode, repContactPhoneNumber) ? 'Please Enter a Valid Phone Number' : null"
                                                                ></HbTextField>
                                                            </v-col>
                                                            <v-col cols="3" class="ma-0 pa-0 mt-3">
                                                                <hb-checkbox
                                                                    v-model="repphone.sms"
                                                                    hide-details
                                                                >
                                                                    <template v-slot:label>
                                                                        <span class="text-capitalize">SMS</span>
                                                                    </template>
                                                                </hb-checkbox>
                                                            </v-col>
                                                            <v-col cols="12" class="pa-0 ma-0 mt-3 d-flex align-center" v-if="edit === 'reps_phone'">
                                                                <hb-btn color="primary" @click="saveAdditionalContact('reps', 'phone')">Save</hb-btn>
                                                                <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    v-else
                                                    class="ma-0 pa-0"
                                                    @mouseover="hoverRevert(); hovering.reps_phone = true, current_row_hovered = 'reps_phone'"
                                                    @mouseleave="hovering.reps_phone = false, current_row_hovered = ''"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="hb-forms-content"
                                                        @click="edit !== 'reps_phone' ? editRow('reps_phone') : ''"
                                                    >
                                                        <div v-if="form.Representative.Contact.Phones.length">
                                                            <v-row class="ma-0 pa-0">
                                                                <v-col cols="11" class="ma-0 pa-0">
                                                                    <div v-for="(repphone, index) in form.Representative.Contact.Phones" :key="index">
                                                                        <span class="pr-2">{{repphone.type}}</span>{{repphone.phone | formatPhone}}
                                                                        <span v-if="repphone.sms" class="pl-1 hb-text-light">Can SMS</span>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="1" class="ma-0 pa-0" align="right">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-hover v-slot:default="{ hover }">
                                                                                <span v-on="on">
                                                                                    <hb-icon v-if="(hovering.reps_phone && current_row_hovered === 'reps_phone') || $vuetify.breakpoint.xs" v-on="on" small :class="hover ? 'hb-text-night' : ''">mdi-pencil</hb-icon>
                                                                                </span>
                                                                            </v-hover>
                                                                        </template>
                                                                        <span>Edit</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                        <div v-else>
                                                            <hb-link  @click="edit !== 'reps_phone' ? editRow('reps_phone') : ''">Click to Add</hb-link>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="hb-table-row ma-0 pa-0">
                                             <v-col class="hb-forms-label" cols="4">
                                                Address
                                            </v-col>
                                            <v-col cols="8" class="ma-0 pa-0" :class="{'hb-form-editable' : edit !== 'reps_address' && edit !== 'reps'}">
                                                <v-row class="mx-0 pb-0 mb-3 mt-3 px-4 hb-forms-content-row" :class="{'hb-half-pixel-fix' : fromTenantProfile}" v-if="edit === 'reps' || edit === 'reps_address'">
                                                    <v-col cols="12" class="ma-0 pa-0" v-for="(rep, index) in form.Representative.Contact.Addresses" :key="index">
                                                        <v-row class="ma-0 pa-0">
                                                            <v-col cols="12" class="hb-z-index-99999 ma-0 pa-0">
                                                                <HbAutocomplete
                                                                    :id="'rep_address_country' + index"
                                                                    :name="'rep_address_country' + index"
                                                                    :items="countriesListMixin"
                                                                    v-validate="'required'"
                                                                    v-model="rep.Address.country"
                                                                    label="Country"
                                                                    single-line
                                                                    data-vv-scope="reps"
                                                                    data-vv-as="Country" 
                                                                    :error="errors.collect('reps.rep_address_country' + index).length > 0"
                                                                    @change="setRepresentativeCountry(0)"
                                                                >
                                                                </HbAutocomplete>
                                                            </v-col>
                                                            <v-col cols="12" class="hb-z-index-99999 hb-forms-max-width-498 ma-0 pa-0 mt-3">
                                                                <HbSelect
                                                                    :id="'rep_address_type' + index"
                                                                    :name="'rep_address_type' + index"
                                                                    :items="location_types"
                                                                    v-validate="((rep.Address.address || rep.Address.address2 || rep.Address.city || rep.Address.state || rep.Address.zip)) ?'required|max:45':''"
                                                                    v-model="rep.type"
                                                                    label="Type"
                                                                    single-line
                                                                    data-vv-as="address type"
                                                                    data-vv-scope="reps"
                                                                    :hide-details="!errors.collect('reps.rep_address_type' + index).length"
                                                                    :error-messages="errors.collect('reps.rep_address_type' + index)"
                                                                    :clearable="true"
                                                                    @click:clear="rep.type = ''"
                                                                ></HbSelect>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="ma-0 pa-0 mt-3">
                                                            <v-col cols="12" class="ma-0 pa-0">
                                                                <HbTextField
                                                                    :id="'rep_address_address' + index"
                                                                    :name="'rep_address_address' + index"
                                                                    v-validate="'required|max:45'"
                                                                    v-model="rep.Address.address"
                                                                    label="Street"
                                                                    single-line
                                                                    data-vv-as="Street"
                                                                    data-vv-scope="reps"
                                                                    :hide-details="!errors.collect('reps.rep_address_address').length"
                                                                    :error-messages="errors.collect('reps.rep_address_address')"
                                                                ></HbTextField>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="ma-0 pa-0 mt-3">
                                                            <v-col cols="12" class="ma-0 pa-0">
                                                                <HbTextField
                                                                    :id="'rep_address_address2' + index"
                                                                    :name="'rep_address_address2' + index"
                                                                    v-model="rep.Address.address2"
                                                                    v-validate="'max:45'"
                                                                    label="Suite / Apt"
                                                                    single-line
                                                                    data-vv-as="Suite / Apt"
                                                                    data-vv-scope="reps"
                                                                    :hide-details="!errors.collect('reps.rep_address_address2' + index).length"
                                                                    :error-messages="errors.collect('reps.rep_address_address2' + index)"
                                                                ></HbTextField>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="ma-0 pa-0 mt-3">
                                                            <v-col cols="3" class="ma-0 pa-0 pr-3">
                                                                <HbTextField
                                                                    :id="'rep_address_zip' + index"
                                                                    :name="'rep_address_zip' + index"
                                                                    v-validate="'required|max:45'"
                                                                    v-model="rep.Address.zip"
                                                                    label="Zip"
                                                                    single-line
                                                                    data-vv-as="zip"
                                                                    data-vv-scope="reps"
                                                                    :hide-details="!errors.collect('reps.rep_address_zip' + index).length"
                                                                    :error-messages="errors.collect('reps.rep_address_zip' + index)"
                                                                ></HbTextField>
                                                            </v-col>
                                                            <v-col cols="4" class="ma-0 pa-0 pr-3"> 
                                                                <HbAutocomplete
                                                                    :id="'reps_address_state' + index"
                                                                    :name="'reps_address_state' + index"
                                                                    :items="relationStateListMixin[0]"
                                                                    v-validate="(((rep.Address.address || rep.Address.address2 || rep.Address.city || rep.Address.zip) && relationStateListMixin[0] && relationStateListMixin[0].length)) ? 'required' :''"
                                                                    v-model="rep.Address.state"
                                                                    label="State"
                                                                    item-text="name"
                                                                    item-value="value"
                                                                    single-line
                                                                    data-vv-scope="reps"
                                                                    :data-vv-name="'reps_address_state'"
                                                                    data-vv-as="state"
                                                                    :error="errors.collect('reps.reps_address_state' + index).length > 0"
                                                                    @change="setRepresentativeState(0)"
                                                                >
                                                                </HbAutocomplete>
                                                            </v-col>
                                                            <v-col cols="5" class="ma-0 pa-0 pl-3 hb-z-index-99999">
                                                                <HbAutocomplete
                                                                    :id="'reps_address_city' + index"
                                                                    :name="'reps_address_city' + index"
                                                                    :items="relationCityListMixin[0]"
                                                                    v-validate="(((rep.type || rep.Address.address || rep.Address.address2 || rep.Address.state || rep.Address.zip) && relationCityListMixin[0] && relationCityListMixin[0].length)) ?'required':''"
                                                                    v-model="rep.Address.city"
                                                                    label="City"
                                                                    single-line
                                                                    data-vv-scope="reps"
                                                                    :data-vv-name="'reps_address_city'"
                                                                    data-vv-as="city"
                                                                    :error="errors.collect('reps.reps_address_city' + index).length > 0"
                                                                    @keydown.enter="setCityList({event: $event, country: rep.Address.country, state: rep.Address.state, cityList: relationCityListMixin, index: 0, toValidate: true})"
                                                                >
                                                                </HbAutocomplete>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0 ma-0 mt-3 d-flex align-center" v-if="edit === 'reps_address'">
                                                        <hb-btn color="primary" @click="saveAdditionalContact('reps','address')">Save</hb-btn>
                                                        <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    v-else
                                                    class="ma-0 pa-0"
                                                    @mouseover="hoverRevert(); hovering.reps_address = true, current_row_hovered = 'reps_address'"
                                                    @mouseleave="hovering.reps_address = false, current_row_hovered = ''"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="hb-forms-content"
                                                        @click="edit !== 'reps_address' ? editRow('reps_address') : ''"
                                                    >
                                                        <div v-if="form.Representative.Contact.Addresses.length && (form.Representative.Contact.Addresses[0].type || form.Representative.Contact.Addresses[0].Address.address || form.Representative.Contact.Addresses[0].Address.address2 || form.Representative.Contact.Addresses[0].Address.state || form.Representative.Contact.Addresses[0].Address.zip || form.Representative.Contact.Addresses[0].Address.city)">
                                                            <v-row class="ma-0 pa-0">
                                                                <v-col cols="11" class="ma-0 pa-0">
                                                                    <div v-show="form.Representative.Contact.Addresses[0].type">{{form.Representative.Contact.Addresses[0].type}}</div>
                                                                    <div v-show="form.Representative.Contact.Addresses[0].Address.address || form.Representative.Contact.Addresses[0].Address.address2">
                                                                        {{form.Representative.Contact.Addresses[0].Address.address}}
                                                                        <span v-if="form.Representative.Contact.Addresses[0].Address.address2"> 
                                                                            {{form.Representative.Contact.Addresses[0].Address.address2}}
                                                                        </span>,
                                                                        {{ form.Representative.Contact.Addresses[0].Address | formatAddress }}
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="1" class="ma-0 pa-0" align="right">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-hover v-slot:default="{ hover }">
                                                                                <span v-on="on">
                                                                                    <hb-icon v-if="(hovering.reps_address && current_row_hovered === 'reps_address') || $vuetify.breakpoint.xs" v-on="on" small :color="hover ? '#101318' : ''">mdi-pencil</hb-icon>
                                                                                </span>
                                                                            </v-hover>
                                                                        </template>
                                                                        <span>Edit</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                        <div v-else>
                                                            <hb-link @click="edit !== 'reps_address' ? editRow('reps_address') : ''">Click to Add</hb-link>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="ma-0 pa-0" style="border: 0px solid #101318"></v-divider>
                        </template>
                    </hb-expansion-panel>

                    <!-- =================================== -->
                        <!-- Business Representative End -->
                    <!-- =================================== -->

                    <!-- =================================== -->
                            <!-- Military Start -->
                    <!-- =================================== -->
                    
                    <hb-expansion-panel v-show="isAdmin" v-if="!nationalAccount">
                        <template v-slot:title>
                            Active Duty Military
                        </template>

                        <template v-slot:content v-if="contact">
                            
                            <active-military 
                                ref="military" 
                                :style="{ 'z-index': 999}"
                                v-model="form.Military" 
                                @save="save('military')"
                                :tenant-profile="true"  
                            ></active-military>
                            <v-row class="ma-0 pa-0 my-2 mr-3 hb-default-font-size" v-if="!form.Military.id">
                                <v-col cols="12" class="pa-0 ma-0 d-flex align-center justify-end">
                                    <hb-link class="mr-2" @click="military_reset">Cancel</hb-link>
                                    <hb-btn color="primary" @click="save('military')">Save</hb-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </hb-expansion-panel>

                    <!-- =================================== -->
                            <!-- Military Ends -->
                    <!-- =================================== -->

                    <!-- =================================== -->
                            <!-- Billing Profile Start -->
                    <!-- =================================== -->

                    <billing-profile v-show="isAdmin" :contact="contact" :property_id="propertyView" v-if="!nationalAccount"/>

                    <!-- =================================== -->
                            <!-- Billing Profile END -->
                    <!-- =================================== -->
                </v-expansion-panels>
            </div>
        </div>
        <!-- Confirmation popup on alternate contact update -->
        <alternate-contact-update-modal
            v-model="showDuplicateContactModal" 
            v-if="showDuplicateContactModal"
            @close="closeDuplicateContactModal()"
            @ok="closeDuplicateContactModal(true)"
        >
        </alternate-contact-update-modal>
    
    </div>

</template>
<script type="text/babel">


    import Status from '../includes/Messages.vue';
    import ACTIVE_MILITARY from '@/constants/active_military.js'; 
    import api from '../../assets/api.js';
    import moment from 'moment';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters, mapActions } from 'vuex';
    import HbDatePicker from '../assets/HummingbirdDatepicker';
    import VuetifyStatepicker from '../includes/VuetifyStatepicker.vue';
    import ViewLead from '../leads/ViewLead.vue';
    import ActiveMilitary from './ActiveMilitary.vue';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import { parsePhoneNumber } from 'libphonenumber-js';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';
    import { validateEmailPhoneMixin } from '../../mixins/validateEmailPhoneMixin';
    import { countryStateCityMixin } from '../../mixins/countryStateCityMixin';
    import AlternateContactUpdateModal from './AlternateContactUpdateModal.vue';
    import BillingProfile from './BillingProfile/Index.vue';    

    export default{
        name: "ContactInformation",
        mixins: [ notificationMixin, validateEmailPhoneMixin, countryStateCityMixin ],
        data(){
            return {
                formDeepCopy: [],
                comboboxLeasesList: [],
                comboboxLeasesListReset: [],
                leaseOptions: [],
                selectedLease: [],
                leaseTracker:[] ,
                active: '',
                selectedOptions: [],
                designatedAsTypes:[
                    {
                        name: "Alternate",
                        id: "is_alternate",
                        disabled: false,
                    },
                    {
                        name: "Emergency",
                        id: "is_emergency",
                        disabled: false,
                    },
                    {
                        name: "Authorized Access",
                        id: "is_authorized",
                        disabled: false,
                    },
                    {
                        name: "Lien Holder",
                        id: "is_lien_holder",
                        disabled: false,
                    }
                ], 
                designatedAsTypesForNa:[
                    {
                        name: "Authorized Access",
                        id: "is_authorized",
                        disabled: false,
                    },
                    {
                        name: "Representative",
                        id: "is_national_account_representative",
                        disabled: false,
                    }
                ], 

                branch: ACTIVE_MILITARY.BRANCHES,
                rank: ACTIVE_MILITARY.RANKS,
                states: [
                    { 'label': 'Alabama', 'value': 'AL' },
                    { 'label': 'Alaska', 'value': 'AK' },
                    { 'label': 'American Samoa', 'value': 'AS' },
                    { 'label': 'Arizona', 'value': 'AZ' },
                    { 'label': 'Arkansas', 'value': 'AR' },
                    { 'label': 'California', 'value': 'CA' },
                    { 'label': 'Colorado', 'value': 'CO' },
                    { 'label': 'Connecticut', 'value': 'CT' },
                    { 'label': 'Delaware', 'value': 'DE' },
                    { 'label': 'District of Columbia', 'value': 'DC' },
                    { 'label': 'Florida', 'value': 'FL' },
                    { 'label': 'Georgia', 'value': 'GA' },
                    { 'label': 'Guam', 'value': 'GU' },
                    { 'label': 'Hawaii', 'value': 'HI' },
                    { 'label': 'Idaho', 'value': 'ID' },
                    { 'label': 'Illinois', 'value': 'IL' },
                    { 'label': 'Indiana', 'value': 'IN' },
                    { 'label': 'Iowa', 'value': 'IA' },
                    { 'label': 'Kansas', 'value': 'KS' },
                    { 'label': 'Kentucky', 'value': 'KY' },
                    { 'label': 'Louisiana', 'value': 'LA' },
                    { 'label': 'Maine', 'value': 'ME' },
                    { 'label': 'Maryland', 'value': 'MD' },
                    { 'label': 'Massachusetts', 'value': 'MA' },
                    { 'label': 'Michigan', 'value': 'MI' },
                    { 'label': 'Minnesota', 'value': 'MN' },
                    { 'label': 'Mississippi', 'value': 'MS' },
                    { 'label': 'Missouri', 'value': 'MO' },
                    { 'label': 'Montana', 'value': 'MT' },
                    { 'label': 'Nebraska', 'value': 'NE' },
                    { 'label': 'Nevada', 'value': 'NV' },
                    { 'label': 'New Hampshire', 'value': 'NH' },
                    { 'label': 'New Jersey', 'value': 'NJ' },
                    { 'label': 'New Mexico', 'value': 'NM' },
                    { 'label': 'New York', 'value': 'NY' },
                    { 'label': 'North Carolina', 'value': 'NC' },
                    { 'label': 'North Dakota', 'value': 'ND' },
                    { 'label': 'Northern Mariana Islands', 'value': 'MP' },
                    { 'label': 'Ohio', 'value': 'OH' },
                    { 'label': 'Oklahoma', 'value': 'OK' },
                    { 'label': 'Oregon', 'value': 'OR' },
                    { 'label': 'Pennsylvania', 'value': 'PA' },
                    { 'label': 'Puerto Rico', 'value': 'PR' },
                    { 'label': 'Rhode Island', 'value': 'RI' },
                    { 'label': 'South Carolina', 'value': 'SC' },
                    { 'label': 'South Dakota', 'value': 'SD' },
                    { 'label': 'Tennessee', 'value': 'TN' },
                    { 'label': 'Texas', 'value': 'TX' },
                    { 'label': 'Utah', 'value': 'UT' },
                    { 'label': 'Vermont', 'value': 'VT' },
                    { 'label': 'Virgin Islands', 'value': 'VI' },
                    { 'label': 'Virginia', 'value': 'VA' },
                    { 'label': 'Washington', 'value': 'WA' },
                    { 'label': 'West Virginia', 'value': 'WV' },
                    { 'label': 'Wisconsin', 'value': 'WI' },
                    { 'label': 'Wyoming', 'value': 'WY'}
                ],
                military_formatted_phone: '',
                editing: {
                    general: false,
                    addresses: false,
                    military: false,
                    vehicles: false,
                    employment: false,
                    contacts: false,
                    leads: false,
                    reps: false
                },
                editLeads : false,
                edit: '',
                form: {
                    salutation: '',
                    first: '',
                    middle: '',
                    last: '',
                    suffix: '',
                    email: '',
                    company: '',
                    notes: '',
                    dob: null,
                    ssn: '',
                    gender: '',
                    driver_license: '',
                    driver_license_exp: '',
                    driver_license_country: '',
                    driver_license_state: '',
                    driver_license_city: '',
                    active_military: false,
                    Vehicles: [],
                    Employment: [],
                    Phones: [],
                    Addresses: [],
                    Relationships:[],
                    Representative:{},
                    Military: {
                        id: {}
                    },
                    Business: {
                        Address:{},
                    },
                    taxpayer_number:''
                },
                email_types: ['Primary', 'Work', 'Other'],
                phone_types: ['Phone', 'Cell','Home','Work','Fax','Other'],
                phone_types_m: ['Cell','Home','Work','Other'],
                location_types: ['Home', 'Work', 'Other'],
                genders:[
                    'no response',
                    'male',
                    "female"
                ],
                open_sections: ['leads'],
                panel: [],
                addressesSubpanel: [],
                contactsSubpanel: [],
                vehiclesSubpanel: [],
                employmentSubpanel: [],
                disableAddressesPanel: false,
                disableContactsPanel: false,
                disableVehiclesPanel: false,
                disableEmploymentPanel: false,
                confirmRemovePhone: false,
                confirmRemoveAddress: false,
                confirmRemoveContact: false,
                confirmRemoveVehicle: false,
                confirmRemoveEmployment: false,
                hovering: {
                    general_name: false,
                    general_email: false,
                    general_phone: false,
                    general_notes: false,
                    general_dob: false,
                    general_ssn: false,
                    general_gender: false,
                    general_license: false,
                    addresses_address: false,
                    addresses_dates: false,
                    addresses_rent: false,
                    addresses_landlord: false,
                    contacts_designate: false,
                    contacts_name: false,
                    contacts_email: false,
                    contacts_phone: false,
                    contacts_address: false,
                    vehicles_general: false,
                    vehicles_plate: false,
                    vehicles_state: false,
                    military_number: false,
                    military_rank: false,
                    military_military: false,
                    military_expiration: false,
                    employment_employer: false,
                    employment_dates: false,
                    employment_position: false,
                    employment_status: false,
                    employment_supervisor: false,
                    employment_salary: false,
                    general_taxpayer_number: false,
                    reps_name: false,
                    reps_email:false,
                    reps_phone:false,
                    reps_address:false
                },
                current_row_hovered: '',
                selectedAddress: '',
                selectedContact: '',
                selectedEmployment: '',
                defaultCountry: 'United States',
                countryCodesList: ["1","7","20","27","30","31","32","33","34","36","39","40","41","43","44","45","46","47","48","49","51","52","53","54","55","56","57","58","60","61","62","63","64","65","66","81","82","84","86","90","91","92","93","94","95","98","211","212","213","216","218","220","221","222","223","224","225","226","227","228","229","230","231","232","233","234","235","236","237","238","239","240","241","242","243","244","245","246","247","248","249","250","251","252","253","254","255","256","257","258","260","261","262","263","264","265","266","267","268","269","290","291","297","298","299","350","351","352","353","354","355","356","357","358","359","370","371","372","373","374","375","376","377","378","380","381","382","383","385","386","387","389","420","421","423","500","501","502","503","504","505","506","507","508","509","590","591","592","593","594","595","596","597","598","599","670","672","673","674","675","676","677","678","679","680","681","682","683","685","686","687","688","689","690","691","692","850","852","853","855","856","880","886","960","961","962","963","964","965","966","967","968","970","971","972","973","974","975","976","977","992","993","994","995","996","998"],
                phoneCode: '1',
                phoneNumber: '',
                invalidPhone: null,
                contactPhoneCode: '1',
                contactPhoneNumber: '',
                repContactPhoneCode: '1',
                repContactPhoneNumber: '',
                contactCopy: null,
                activeEdit: [],
                isSavingLoad: false,
                showDuplicateContactModal: false,
                confirmationModal: true,
                altScope: '',
                altField: '',
                altIndex: '',
                altRelationship: '',
                spaceDesignationPanel: [0]
            }
        },
        provide() {
            return {
                $validator: this.$validator,
            }
        },
        props: {
            contact: {
                type: Object,
                required: true
            },
            fromTenantProfile: {
                type: Boolean,
                default: false
            },
            propertyView: {
                type: String,
                default: ''
            },
            nationalAccount:{ // if national account this will become true
                type: Boolean,
                default: false
            }
        },
        filters:{
            canSMS(sms){
                return sms ? 'Yes': 'No';
            }
        },
        components:{
            Status,
            HbDatePicker,
            VuetifyStatepicker,
            ViewLead,
            ActiveMilitary,
            AlternateContactUpdateModal,
            BillingProfile
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
                hasPermission: 'authenticationStore/rolePermission',
            }),
            isActiveMilitary(){
                return this.contact.active_military ? 'Yes': 'No';
            },

            containsIsAlternate() {
                return (i) => {
                    // let designationsData = this.form.Relationships[i]?.selected_designations_list;
                    // if(designationsData?.length){
                    //     return designationsData.some(option => option.id === "is_emergency" || option.id === "is_authorized");
                    // }
                    return false;
                };
            },

            isEmailRequired() {
                return (i) => {
                    let designationsData = this.form.Relationships[i]?.selected_designations_list;
                    if(designationsData?.length){
                        return designationsData.some(option => option.id === "is_authorized");
                    }
                };
            },

            comboLeaseOptions(){
                let tempLeases = this.propertyView ? this.contact.Leases.filter(l => l.Unit.property_id === this.propertyView) : this.contact.Leases;
                if(tempLeases.length > 0) tempLeases = tempLeases.map(item => item.id);
                else tempLeases = [];
                let mutated_arr = this.leaseOptions?.length ? this.leaseOptions: [];
                if(mutated_arr?.length) {
                    mutated_arr = mutated_arr.map(item => {
                        return {
                            id: item.id,
                            name: item.Lease.Unit.number
                        }
                    })
                };
                mutated_arr = tempLeases.length ? mutated_arr.filter(item => tempLeases.includes(item.id)) : mutated_arr;
                this.comboboxLeasesList = mutated_arr;
                this.comboboxLeasesListReset = mutated_arr;
                return mutated_arr;
            },
            isRentAsBusiness(){
                if(!this.contact.rent_as_business){
                    this.phone_types = this.phone_types.filter(type => type != 'Office Phone');
                }else{
                    this.phone_types.unshift("Office Phone");
                }
                return this.contact.rent_as_business;
            }
        },
        created(){ 
            this.setCountriesListEvent()
            EventBus.$on('new_lead_click', this.turnOffEditing);
            
            this.updateForm(JSON.parse(JSON.stringify(this.contact)));
            this.currentDate = moment().format('YYYY-MM-DD');

            if(!this.fromTenantProfile){
                setTimeout(() => {
                    this.panel = [0];
                }, 200);
            }

            this.checkAddressesIsEmpty();
            this.checkContactsIsEmpty();
            // this.checkVehiclesIsEmpty();
            // this.checkEmploymentIsEmpty();

            this.contact.Addresses.forEach( (address, i) => {
                this.addressesSubpanel.push(i);
            });

            this.contactCopy.Relationships.forEach( (r, i) => {
                this.contactsSubpanel.push(i);
            });

            this.setLeaseOptions();

        },
        destroyed(){
            EventBus.$off('new_lead_click', this.turnOffEditing);
            this.resetMissingCityList()
        },
        methods:{
            designationAndSpaceValidations(index){
               // check if alternate or lienHolder or Authorized is selected but space is not selected
		        let designate = 'is_alternate';
                let hasError = false;
                let current_contact = this.form.Relationships[index];
                for (let i = 0; i < this.form.Relationships.length; i++) {
                    const item = this.form.Relationships[i];
                    let selectedDesignations = item?.selected_designations_list;
                    let check_is_alternate;
                    if(i === index) {
                        if (selectedDesignations && selectedDesignations.length === 1 && selectedDesignations[0].id === 'is_emergency' && !item?.selected_leases_list?.length) {
                            const leases = this.contact.Leases.filter(l => !(moment(l.end_date).startOf('day') <= moment().startOf('day')) );
                            item.selected_leases_list = leases.map(l_data => {
                                return {
                                    id: l_data.id,
                                    name: l_data.Unit.number
                                }
                            })
                        } 

                        if(!item.selected_leases_list?.length){
                            this.showMessageNotification({ type: 'error', description: `Please select space to associate with contact(${item.Contact.first} ${item.Contact.last}).` });
                            hasError = true;
                        }
                    }
                };
                return hasError;
            },
	        trimEmail() {
	           // Trim or exclude empty spaces from the email
	           this.form.email = this.form.email.trim();
	        },
            setCountry(index){
                this.form.Addresses[index].Address.state= ''
                this.form.Addresses[index].Address.city= ''
                this.setStatesListEvent(this.form.Addresses[index].Address.country, index)
                this.setCountryCitiesListEvent(this.form.Addresses[index].Address.country, index)
            },
            setState(index){
                this.form.Addresses[index].Address.city = ''
                this.setCitiesListEvent(this.form.Addresses[index].Address.country, this.form.Addresses[index].Address.state, index)
            },
            setCityList(payload){
                const {event, country, state, cityList} = payload
                const toValidate =  payload?.toValidate || false
                const index = payload?.index >= 0 ? payload?.index : -1
                const {value, dataset} = event.target
                if(!value) return
                if(toValidate) {
                    const name= `${dataset.vvScope}.${dataset.vvName}`
                    const isSpecialCharacter = this.validateCityName(value)
                    const cityValidation = this.errors.items.indexOf(name)

                    this.errors.items.splice(cityValidation);
                    if(isSpecialCharacter){
                        return this.errors.add({
                                    field: name,
                                    msg: 'Please add a valid city name.'
                                }) 
                    }
                }

                const cityName = value.toString()
                const cityData = (index >= 0) ? cityList[index] : cityList;
                const isCityExists = cityData.some((city) => city.toLowerCase() === cityName.toLowerCase())

                if(isCityExists) return

                if(index >= 0){
                    this.$set(cityList, index, [...cityData, value])
                }else{
                    cityList.push(value)
                }

                const countryData = this.countriesListMixinRaw.find(item => item.name === country);
                this.addMissingCity({
                    country_code: countryData.isoCode,
                    state_code: state,
                    city: value
                })
            },
            setDLCountry(){
                this.form.driver_license_state = ''
                this.setDLStatesListEvent(this.form.driver_license_country)
            },
            setRelationCountry(index){
                this.form.Relationships[index].Contact.Addresses[0].Address.state = ''
                this.form.Relationships[index].Contact.Addresses[0].Address.city = ''
                this.setRelationStatesListEvent(this.form.Relationships[index].Contact.Addresses[0].Address.country, index)
                this.setRelationCountryCitiesListEvent(this.form.Relationships[index].Contact.Addresses[0].Address.country, index)
            },
            setRelationState(index){
                this.form.Relationships[index].Contact.Addresses[0].Address.city = ''
                this.setRelationCitiesListEvent(this.form.Relationships[index].Contact.Addresses[0].Address.country, this.form.Relationships[index].Contact.Addresses[0].Address.state, index)
            },
            setRepresentativeCountry(index){
                this.form.Representative.Contact.Addresses[0].Address.state = ''
                this.form.Representative.Contact.Addresses[0].Address.city = ''
                this.setRelationStatesListEvent(this.form.Representative.Contact.Addresses[0].Address.country,index)
                this.setRelationCountryCitiesListEvent(this.form.Representative.Contact.Addresses[0].Address.country,index)
            },
            setRepresentativeState(index){
                this.form.Representative.Contact.Addresses[0].Address.city = ''
                this.setRelationCitiesListEvent(this.form.Representative.Contact.Addresses[0].Address.country, this.form.Representative.Contact.Addresses[0].Address.state, index)
            },
            validateEmailPhone(){
              // Purpose of this method is to check if either Email or Phone is added

              // This condition is to check if both are not added and returns true otherwise false
              if(!this.form.email && (!this.form.Phones[0]?.phone && !this.phoneNumber)){
                return true
              }
              return false
            },
            military_reset(){
                this.$refs.military.loadCache();
            }, 
            militaryPhoneNumber(data){
                this.form.Military.phone = data.formattedNumber;
                this.form.Military.country_code = data.countryCode;
            },
            hasSectionError(section){
              if(!this.errors || !this.errors.items || !this.errors.items?.length) return false;

              return this.errors.items.find(i => i.scope == section);
            },
            setExpDate(){
                this.form.driver_license_exp = '';
                
            },
            turnOffEditing(){
                this.editing = false;
            },

            toggleSection(section){
                 if(this.open_sections.indexOf(section) >= 0 ){
                     this.open_sections.splice(this.open_sections.indexOf(section), 1);

                 } else {
                     this.open_sections.push(section)
                 }
            },
            showSection(section){
                if(this.open_sections.indexOf(section) < 0 ){
                  this.open_sections.push(section)
                }
            },

            isOpen(section){
                return this.open_sections.indexOf(section) >= 0
            },
            startEditing(section){
                this.updateForm(JSON.parse(JSON.stringify(this.contact)));
                this.editing[section] = true;
            },
            stopEditing(section){
                this.editing[section] = false;
                this.updateForm(JSON.parse(JSON.stringify(this.contact)));
            },
            addPhone(i){
                this.clearAllEditing();
                this.form.Phones.push({
                    id: null,
                    phone: '',
                    type: '',
                    sms: false
                });
                this.phoneCode = '1';
                this.phoneNumber = '';
                this.edit = "general_phone" + i;
            },
            addAddress(i){
                this.clearAllEditing();
                this.form.Addresses.push({
                    id: null,
                    type: '',
                    move_in: '',
                    move_out: '',
                    rent: '',
                    landlord: '',
                    phone: '',
                    Address: {
                        address: '',
                        address2: '',
                        country: this.defaultCountry,
                        city: '',
                        state: '',
                        zip: ''
                    }
                });
                this.setStatesListEvent(this.form.Addresses[i].Address.country, i)
                this.setCountryCitiesListEvent(this.form.Addresses[i].Address.country, i)
                this.addressesSubpanel = [];
                this.addressesSubpanel.push(i);
                this.edit = "addresses" + i;
                if(i === 0 && !this.panel.includes(1)){
                    this.panel.push(1);
                }
            },
            addRelationship(i){
                if(!this.hasPermission('edit_business_rep_and_alc')){
                    this.showMessageNotification({ description: 'You do not have permission to add/edit/remove the Business Representative & Alternate Contact.' }) 
                    return;
                }
                this.clearAllEditing();
                this.form.Relationships.push({
                    id: null,
                    related_contact_id: null,
                    lease_data: [],
                    selected_leases_list: [],
                    selected_designations_list: [],
                    type: '',
                    Contact: {
                        id: null,
                        first: '',
                        last: '',
                        email: '',
                        Phones: [
                            {
                                id: null,
                                phone: '',
                                type: '',
                                sms: false
                            }
                        ],
	                    Addresses:[{
                        	type:'',
                            Address:{
		                        address: '',
                            address2: '',
                            country: this.defaultCountry,
                            city: '',
                            state: '',
                            zip: '',
                        	},
	                    }]
                    },
                });
                this.setRelationStatesListEvent(this.defaultCountry, i)
                this.setRelationCountryCitiesListEvent(this.defaultCountry, i)
                this.contactsSubpanel = [];
                this.contactsSubpanel.push(i);
                this.edit = 'contacts' + i;
                if(i === 0 && !this.panel.includes(2)){
                    if(this.nationalAccount){
                        this.panel.push(0) // reason : - we only have 1 panel
                    }else{
                        this.panel.push(2);
                    }
                  
                }
                this.showEditDesignationEvent({ index: i });
            },
            deletePhone(phone, i){
                this.clearAllEditing();
                this.form.Phones.splice(i, 1);
                this.save('general');
                this.confirmRemovePhone = false;
            },
            removeAddress(i){
                this.selectedAddress = i;
                this.confirmRemoveAddress = true;
            },
            deleteAddress(i){
                this.clearAllEditing();
                this.form.Addresses.splice(i, 1);
                this.save('addresses');
                this.confirmRemoveAddress = false;
            },
            deleteVehicle(vehicles, i){
                this.clearAllEditing();
                this.form.Vehicles.splice(i, 1);
                this.save('vehicles');
                this.confirmRemoveVehicle = false;
            },
            removeEmployment(i){
                this.selectedEmployment = i;
                this.confirmRemoveEmployment = true;
            },
            deleteEmployment(i){
                this.clearAllEditing();
                this.form.Employment.splice(i, 1);
                this.save('employment');
                this.confirmRemoveEmployment = false;
            },
            removeContact(i){
                if(!this.hasPermission('edit_business_rep_and_alc')){
                    this.showMessageNotification({ description: 'You do not have permission to add/edit/remove the Business Representative & Alternate Contact.' }) 
                    return;
                }
                this.selectedContact = i;
                this.confirmRemoveContact = true;
            },
            deleteRelationship(i){
                this.clearAllEditing();
                this.form.Relationships.splice(i, 1);
                this.save('contacts');
                this.confirmRemoveContact = false;
            },
            updateForm(data){
                this.contactCopy = data;
                this.form = {
                    salutation: data.salutation,
                    first: data.first,
                    middle: data.middle,
                    last: data.last,
                    suffix: data.suffix,
                    email: data.email,
                    notes: data.notes,
                    company: data.company,
                    dob: data.dob,
                    ssn: data.ssn ? data.ssn.toString(): null,
                    gender: data.gender,
                    driver_license: data.driver_license,
                    driver_license_exp: moment(data.driver_license_exp).format('YYYY-MM-DD') === 'Invalid date' ? '' : moment(data.driver_license_exp).format('YYYY-MM-DD'),
                    driver_license_country: data.driver_license_country || this.defaultCountry,
                    driver_license_state: data.driver_license_state,
                    driver_license_city: data.driver_license_city,
                    active_military: data.active_military ? true: false,
                    taxpayer_number: data.taxpayer_number,
                    Phones: [],
                    Addresses: [],
                    Relationships: [],
                    Representative: {},
                    Vehicles: [],
                    Employment: [],
                    Military: {}
                }
                if(data.Leases){
                    this.leaseTracker = data.Leases
                }
                
                this.setDLStatesListEvent(this.form.driver_license_country)

                if(data.Military.id){
                    this.form.Military = data.Military
                    this.form.Military.Address.country = this.setCountryNameEvent(this.form.Military.Address?.country)
                }


                if(data.Phones){
                    this.form.Phones = data.Phones.map(p => {
                        return {
                            id: p.id,
                            sms: p.sms,
                            phone: p.phone,
                            type: p.type,
                            verificationToken: p?.verification_token,
                            phone_verified: p?.phone_verified
                        }
                    })
                }


                const addressesScope = 'addresses'
                const contactsScope = 'contacts'
                const repsScope = 'reps'
                
                if(data.Addresses) {
                    this.form.Addresses = data.Addresses.map((a, i)=> {
                        const countryValue = this.setCountryNameEvent(a.Address.country)
                        const fieldName = `address_city_${i}`
                        const targetValue = {target:{value: a.Address.city, dataset: {vvScope: addressesScope, vvName: fieldName}}}

                        this.setStatesListEvent(countryValue, i)
                        if(!a.Address.state){
                            this.setCountryCitiesListEvent(countryValue, i)
                        }else{
                            this.setCitiesListEvent(countryValue, a.Address.state, i)
                        }
                        this.setCityList({event: targetValue, country: countryValue, state: a.Address.state, cityList: this.cityListMixin, index: i})
                        
                        return {
                            id: a.id,
                            type: a.type,
                            Address: {
                                address: a.Address.address,
                                address2: a.Address.address2,
                                city: a.Address.city,
                                state: a.Address.state,
                                zip: a.Address.zip,
                                country: countryValue
                            }
                        }
                    })
                }
                
                if(data.Vehicles){
                    this.form.Vehicles = data.Vehicles.map(p => {
                        return {
                            id: p.id,
                            make: p.make,
                            model: p.model,
                            year: p.year,
                            state: p.state,
                            plate: p.plate,

                            /* need to add color field to api/databse */
                            /*
                            color: p.color,
                            */
                        }
                    })
                }

                if(data.Employment){
                    this.form.Employment = data.Employment.map(p => {
                        return {
                            id: p.id,
                            employer: p.employer,
                            start_date: p.start_date ? moment(p.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY'): null,
                            end_date: p.end_date ? moment(p.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY'): null,
                            position: p.position,
                            status: p.status && p.status[0] && p.status[0]?.length > 0 ? p.status : [],
                            supervisor: p.supervisor,
                            phone: p.phone,
                            salary: p.salary,
                            salary_timeframe: p.salary_timeframe
                        }
                    })
                }

                if(data.Relationships){
                    this.form.Relationships = this.formatContactData(data.Relationships).map((p, index) => {
                        var phones = [];
                        if(p.Contact.Phones?.length){
                            phones = p.Contact.Phones.map(p => {
                                return {
                                    id: p.id,
                                    phone: p.phone?.length === 10 ? `1${p.phone}` : p.phone,
                                    type: p.type,
                                    sms: p.sms ? true: false
                                }
                            })
                        } else {
                            phones = [{
                                id: null,
                                phone: '',
                                type: '',
                                sms: false
                            }]
                        }


                        var addresses = {};
                      if(p.Contact.Addresses?.length){
		                    addresses = p.Contact.Addresses.map(a => {
                                const countryValue = this.setCountryNameEvent(a.Address.country)
                                const fieldName = `relationship_address_city_${index}`
                                const targetValue = {target:{value: a.Address.city, dataset: {vvScope: contactsScope, vvName: fieldName}}}
                                this.setRelationStatesListEvent(countryValue, index)
                                if(!a.Address.state){
                                    this.setRelationCountryCitiesListEvent(countryValue, index)
                                }else{
                                    this.setRelationCitiesListEvent(countryValue, a.Address.state, index)
                                }
                                this.setCityList({event: targetValue, country: countryValue, state: a.Address.state, cityList: this.relationCityListMixin, index})

			                    return {
				                    id: a.id,
				                    type: a.type,
				                    Address:{
					                    address: a.Address.address,
					                    address2: a.Address.address2,
					                    country: countryValue,
					                    city: a.Address.city,
					                    state: a.Address.state,
					                    zip:  a.Address.zip
                                    }
			                    }
		                    })
	                    } else {
		                    addresses = [{
			                    id: null,
			                    type: '',
			                    Address:{
				                    address: '',
				                    address2: '',
				                    city: '',
				                    state: '',
                                    country: this.defaultCountry,
				                    zip:  ''
			                    }
		                    }]
                            this.setRelationStatesListEvent(this.defaultCountry, index)

	                    }

                        return {
                            id: p.id || "",
                            related_contact_id: p.related_contact_id,
                            lease_data: p.lease_data,
                            selected_leases_list: p.lease_data && p.lease_data.length ? p.lease_data.map(item => {
                                if (item.lease) {
                                    return {
                                        id: item.lease,
                                        name: item.unit_number
                                    }
                                }
                            }).filter(fiter_item => fiter_item) : [],
                            selected_designations_list: [],
                            type: p.type,
                            Contact: {
                                id: p.Contact.id,
                                first: p.Contact.first,
                                last: p.Contact.last,
                                email: p.Contact.email,
                                Phones: phones,
                                Addresses: addresses
                            }
                        }
                    })
                }
                if(Object.keys(data.Representative).length && data.Representative.Contact?.id){
                     this.form.Representative = data.Representative;
                     if(data.Representative.Contact.Addresses.length == 0){ 
                        this.form.Representative.Contact.Addresses = [{
			                    id: null,
			                    type: '',
			                    Address:{
				                    address: '',
				                    address2: '',
				                    city: '',
				                    state: '',
                                    country: this.defaultCountry,
				                    zip:  ''
			                    }
		                    }]
	                }else{
                        this.form.Representative.Contact.Addresses.map(a => {
                                const countryValue = this.setCountryNameEvent(a?.Address?.country);
                                const fieldName = `rep_address_city`;
                                const index = 0;
                                const targetValue = {target:{value: a?.Address?.city, dataset: {vvScope: repsScope, vvName: fieldName}}};
                                this.setRelationStatesListEvent(countryValue, index);
                                if(!a?.Address?.state){
                                    this.setRelationCountryCitiesListEvent(countryValue, index)
                                }else{
                                    this.setRelationCitiesListEvent(countryValue, a?.Address?.state, index)
                                }
                                this.setCityList({event: targetValue, country: countryValue, state: a?.Address?.state, cityList: this.relationCityListMixin, index})
		                });
                    }
                }
                this.formDeepCopy = JSON.parse(JSON.stringify(this.form.Relationships)).filter((obj, index, arr) => {
                    // Filter objects based on the first occurrence of their id
                    return arr.findIndex(item => item.related_contact_id === obj.related_contact_id) === index;
                });
            },
            async closeDuplicateContactModal(confirm = false) {
              this.showDuplicateContactModal = false;
              if(confirm){
                await this.save(this.altScope, this.altField, this.altIndex, this.altRelationship);
              }
            },
            async saveAdditionalContact(scope, field, i, relationship){
                let saveMe = true;
                if(scope == 'contacts' || scope === 'reps'){
                    this.altScope = scope;
                    this.altField = field;
                    this.altIndex = i;
                    this.altRelationship = relationship;
                }
                if(Object.keys(this.form.Representative).length && this.form.Representative?.related_contact_id && this.form.Relationships.length){
                    let contactId = this.form.Representative?.related_contact_id;
                    let repEmail = this.form.Representative?.Contact?.email;
                    if(relationship){
                        let relationshipContactId = relationship?.related_contact_id;
                        if(relationshipContactId === contactId){
                            this.showDuplicateContactModal = true; 
                            saveMe = false;
                        } 
                    }else{
                        let found = this.form.Relationships.find((relationship) => (relationship.related_contact_id === contactId));
                        if(found){
                            this.showDuplicateContactModal = true; 
                            saveMe = false;
                        }
                    } 
                }
                if(saveMe){
                    await this.save(this.altScope, this.altField, this.altIndex, this.altRelationship);
                }          
            },
            async save(scope, field, i, r){
                //let sections = ['general', 'addresses', 'military', 'contacts', 'vehicles', 'employment'];
                let errors = [];
                let status = await this.$validator.validateAll(scope);
                if (!status){
                    this.showSection(scope);
                    errors.push(scope)
                }
                else {
                    this.editing[scope] = false;
                }

                if(field === 'phone' && scope === 'general' && !this.phoneNumber){
                    this.form.Phones[i].phone = this.phoneNumber
                }

                const shouldReturn = this.showEmailPhoneValidation(this.validateEmailPhone(), this.errors)

                if(shouldReturn){
                    return
                }

                if(scope === 'military'){
                    let v = await this.$refs['military'].$validator.validateAll();
                    if(!v){
                        errors = errors.concat(this.$refs['military'].$validator.errors.items.map(e => e.msg))
                    }
                    if(errors?.length){
                        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: errors });
                        return;
                    }
                }

                if (this.form.Military.date_of_birth === '') {
                    this.form.Military.date_of_birth = null;
                }

                if (errors?.length) {
                    this.errorSet(this.$options.name, "There are errors in the "+ errors +" section. Please correct them before continuing.");
                    return
                }                
                                
                if(scope === 'contacts' && this.designationAndSpaceValidations(i)) {
                    return;
                }
                if(field === 'phone'){
                    let parsedPhoneNumber;

                    if(scope === 'general' && this.phoneNumber)
                        parsedPhoneNumber = parsePhoneNumber('+' + this.phoneCode + this.phoneNumber);
                   
                    else if(scope === 'contacts')
                        parsedPhoneNumber = parsePhoneNumber('+' + this.contactPhoneCode + this.contactPhoneNumber);

                    else if(scope === 'reps')
                        parsedPhoneNumber = parsePhoneNumber('+' + this.repContactPhoneCode + this.repContactPhoneNumber);

                    if(parsedPhoneNumber && parsedPhoneNumber.isValid() && scope === 'general' && !this.validateEmailPhone()){
                        this.form.Phones[i].phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                    }
                    else if(parsedPhoneNumber && parsedPhoneNumber.isValid() && scope === 'contacts'){
                        this.form.Relationships[i].Contact.Phones[0].phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                    }
                    else if(parsedPhoneNumber && parsedPhoneNumber.isValid() && scope === 'reps'){
                        this.form.Representative.Contact.Phones[0].phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                    }
                }


                if(i >=0 && !this.form.Phones[i]?.phone){
                    this.form.Phones.splice(i, 1)
                }

                if(this.validateEmailPhone()){
                  return
                }
                this.form.driver_license_state = this.form.driver_license_state || ''

                var data = JSON.parse(JSON.stringify(this.form));

                if(this.isRentAsBusiness){
                    data.rent_as_business = true;
                }else{
                    data.rent_as_business = false;
                }

                data.updatedFieldName = field;

                data.dob = data.dob ? moment(data.dob).format('YYYY-MM-DD'): null;
                data.Employment = data.Employment.map(e => {
                    e.end_date = e.end_date ?  moment(e.end_date).format('YYYY-MM-DD'): null;
                    e.start_date = e.start_date ?  moment(e.start_date).format('YYYY-MM-DD'): null;
                    return e;
                })


                data.Addresses = data.Addresses.map(e => {
                    e.move_in = e.move_in ?  moment(e.move_in).format('YYYY-MM-DD'): null;
                    e.move_out = e.move_out ?  moment(e.move_out).format('YYYY-MM-DD'): null;
                    return e;
                })
                let jsonArr = [];
                let tempRelationships = [...this.contact.Relationships];
                data.Relationships.forEach((relation, index_rel) => {
                    let rel_data = data.Relationships[index_rel]
                    let leases = rel_data.selected_leases_list;
                    leases.forEach((item, index) => {
                        let leaseData = rel_data.lease_data.find(lease_item => lease_item.lease === item.id);
                        let hasDesignationLength = rel_data.selected_designations_list?.length ? true : false;
                        let jsonObj = {
                            "id": leaseData ? leaseData.id : '',
                            "related_contact_id": rel_data.related_contact_id ? rel_data.related_contact_id : '',
                            "lease_id": item.id,
                            "type": rel_data.type,
                            "is_alternate": hasDesignationLength ? rel_data.selected_designations_list.filter(designation => designation.id === 'is_alternate').length : leaseData.designations.length ? leaseData.designations.filter(designation => designation.id === 'is_alternate').length : 0,
                            "is_cosigner": hasDesignationLength ? rel_data.selected_designations_list.filter(designation => designation.id === 'is_cosigner').length : leaseData.designations.length ? leaseData.designations.filter(designation => designation.id === 'is_cosigner').length : 0,
                            "is_emergency": hasDesignationLength ? rel_data.selected_designations_list.filter(designation => designation.id === 'is_emergency').length : leaseData.designations.length ? leaseData.designations.filter(designation => designation.id === 'is_emergency').length : 0,
                            "is_military": hasDesignationLength ? rel_data.selected_designations_list.filter(designation => designation.id === 'is_military').length : leaseData.designations.length ? leaseData.designations.filter(designation => designation.id === 'is_military').length : 0,
                            "is_authorized": hasDesignationLength ? rel_data.selected_designations_list.filter(designation => designation.id === 'is_authorized').length : leaseData.designations.length ? leaseData.designations.filter(designation => designation.id === 'is_authorized').length : 0,
                            "is_lien_holder": hasDesignationLength ? rel_data.selected_designations_list.filter(designation => designation.id === 'is_lien_holder').length : leaseData.designations.length ? leaseData.designations.filter(designation => designation.id === 'is_lien_holder').length : 0,
                            "is_national_account_representative": hasDesignationLength ? rel_data.selected_designations_list.filter(designation => designation.id === 'is_national_account_representative').length : leaseData.designations.length ? leaseData.designations.filter(designation => designation.id === 'is_national_account_representative').length : 0,
                            "is_business_representative":0,
                            "Contact": rel_data.Contact
                        }
                        if(jsonObj.Contact?.Phones && jsonObj.Contact?.Phones.length) {
                            delete jsonObj.Contact.Phones[0].phone_verified;
                            delete jsonObj.Contact.Phones[0].verification_token;
                        }
                        jsonArr.push(jsonObj);
                    })
                });
                let tempLeases = this.comboLeaseOptions.map(item => item.id);

                if(scope == 'contacts' && i !== undefined && i !== null && i !== ''){
                        const item = this.form.Relationships[i];
                        if(item){
                            if(!item?.selected_designations_list?.length){

                                for (let k = 0; k < item.lease_data?.length; k++) {

                                    let check_is_authorized = item?.lease_data[k]?.designations.some(option => option.id === "is_authorized");
                                    if(check_is_authorized && item.Contact.email == ''){
                                        this.showMessageNotification({ type: 'error', description: `Email is required, For ${item.Contact.first} ${item.Contact.last} as Authorized Access.` });
                                        return;
                                    }
                                }
                            }else{

                                let selectedDesignations = item?.selected_designations_list;
                                let checkselectedDesignations = selectedDesignations.some(option => option.id === "is_authorized");

                                if(checkselectedDesignations && item.Contact.email === ''){
                                    this.showMessageNotification({ type: 'error', description: `Email is required, For ${item.Contact.first} ${item.Contact.last} as Authorized Access.` });
                                    return;
                                }                        
                            }
                        }
                }


                const originalRelatedContactIds = this.contactCopy.Relationships.map(relationship => relationship.related_contact_id);
                const currentRelatedContactIds = data.Relationships.map(relationship => relationship.related_contact_id);
                const deletedRelatedContactId = originalRelatedContactIds.find(id => !currentRelatedContactIds.includes(id));
                tempRelationships.forEach((item, index) => {
                    if(!deletedRelatedContactId || (deletedRelatedContactId && deletedRelatedContactId !== item.related_contact_id)) {
                        if(!tempLeases.includes(item.lease_id)) {
                            let contactFound = jsonArr.find(new_contact => new_contact.related_contact_id === item.related_contact_id && new_contact.Contact.id === item.Contact.id);
                            if(contactFound) item["Contact"] = contactFound.Contact;
                            if(item.Contact?.Phones && item.Contact?.Phones.length) {
                                delete item.Contact.Phones[0].phone_verified;
                                delete item.Contact.Phones[0].verification_token;
                            }
                            jsonArr.push(item);
                        }
                    }
                });
                data.Relationships = jsonArr;
                if(Object.keys(this.form.Representative).length && this.form.Representative?.Contact?.id){
                    this.form.Representative.Contact.Phones = this.form.Representative.Contact.Phones.map(p => {
                            return {
                                    id: p.id,
                                    type: p.type,
                                    phone: p.phone,
                                    sms: !!p.sms
                            }
                    });
                    data.Relationships.push(this.form.Representative);
                }
                try {
                    if (data.hasOwnProperty('Representative')) {
                        delete data.Representative;
                    }
                    this.isSavingLoad = true;
                    let r = await api.put(this, api.CONTACTS + this.contact.id + `${scope ? "?scope="+ scope : ""}`,  data);
                    
                    if(r.military_id){
                        this.contact.Military.id = this.form.Military.id = r.military_id
                    }
                    EventBus.$emit('contact_updated',  this.contact.id);
                    // TODO : above event is not emitting some time.
                    if(this.nationalAccount)  this.$emit('fetchContact')
                    EventBus.$emit('reload_tenant_profile_leases');
                    this.edit = '';
                    this.activeEdit = [];
                } catch( err ) {
                    this.errorSet(this.$options.name, err.toString());
                } finally {
                    this.isSavingLoad = false;
                }

            },

            formatContactData(params) {
                let tempLeases = this.propertyView ? this.contact.Leases.filter(l => l.Unit.property_id === this.propertyView) : this.contact.Leases;
                if(tempLeases.length > 0) {
                    tempLeases = tempLeases.map(item => item.id);
                } else {
                    tempLeases = []
                }
                if (this.nationalAccount) {
                    params = params.filter(item => item.is_national_account_alt != 1);
                }
                let contacts = params.map(item => item.related_contact_id);
                contacts = [...new Set(contacts)];
                let response = [];
                contacts.forEach(item => {
                    let contactWithLeases = params.filter(l => l.related_contact_id === item && tempLeases.includes(l.lease_id));
                    if (contactWithLeases.length > 0) {
                    let obj = {};
                        let leases = contactWithLeases;
                    let contactData = leases[0];
                    obj = {
                        "related_contact_id": contactData.related_contact_id,
                        "type": contactData.type,
                        "Contact": contactData.Contact
                    }
                    let leaseData = [];
                    leases.forEach(lea => {
                        let foundLease = this.contact.Leases.find(l_item => l_item.id === lea.lease_id);
                        let data = this.designatedAsTypes.filter((option) => lea[option.id] === 1);
                        let obj = {
                            id: lea.id,
                            lease: lea.lease_id,
                            unit_number: foundLease ? foundLease.Unit.number : null,
                            designations: data
                        }
                        leaseData.push(obj)
                    })
                    obj = {
                        ...obj,
                        lease_data: leaseData
                    }
                        response.push(obj);
                    }
                });
                this.contactCopy.Relationships = response;
                return response;
            },
            clearAllEditing(source){
                this.updateForm(JSON.parse(JSON.stringify(this.contact)));
                this.edit = '';
                this.contactPhoneNumber = null;
                this.hoverRevert();
            },
            editRow(field, i){
                if(!this.hasPermission('edit_business_rep_and_alc') && (field.includes("reps_name") || field.includes("reps_email") || field.includes("reps_phone") || field.includes("reps_address") || field.includes("contacts_name") || field.includes("contacts_email") || field.includes("contacts_phone") || field.includes("contacts_address"))){
                    this.showMessageNotification({ description: 'You do not have permission to add/edit/remove the Business Representative & Alternate Contact.' }) 
                    return;
                }
                this.clearAllEditing();

                if(field === 'general_birthdate'){
                    this.form.dob = this.contact.dob ? moment(this.contact.dob).format('MM-DD-YYYY') : '';
                }

                if(field.includes("addresses_address")){
                    const index = parseInt(field.charAt(field?.length - 1))
                    if(index >=0){
                        if(this.form.Addresses[index].Address.country){
                            this.setStatesListEvent(this.form.Addresses[index].Address.country, index)  
                            if(!this.form.Addresses[index].Address.state){
                                this.setCountryCitiesListEvent(this.form.Addresses[index].Address.country, index)
                            }else{
                                this.setCitiesListEvent(this.form.Addresses[index].Address.country, this.form.Addresses[index].Address.state, index)
                            }
                        }
                  }
                }
                if(field.includes("reps_address")){
                    let index = 0;
                    if(this.form.Representative.Contact.Addresses[index].Address.country){
                        this.setStatesListEvent(this.form.Representative.Contact.Addresses[index].Address.country, index)  
                        if(!this.form.Representative.Contact.Addresses[index].Address.state){
                            this.setRelationStatesListEvent(this.form.Representative.Contact.Addresses[index].Address.country, index)  
                        }else{
                            this.setRelationCountryCitiesListEvent(this.form.Representative.Contact.Addresses[index].Address.country, index)
                        }
                    }
                }
                if(field === 'general_phone' + i){

                    try {
                      var parseEditedPhone = parsePhoneNumber('+' + this.form.Phones[i].phone);
                    } catch(err) {}


                    if(!parseEditedPhone){
                        this.phoneCode = '';
                        this.phoneNumber = this.form.Phones[i].phone;
                    } else {
                        this.phoneCode = parseEditedPhone.countryCallingCode;
                        this.phoneNumber = parseEditedPhone.nationalNumber;
                    }
                }
                if(field === 'contacts_phone' + i){
                    try {
                        var parseEditedPhone = parsePhoneNumber('+' + this.form.Relationships[i].Contact.Phones[0].phone);
                    } catch(err) {}


                    if(!parseEditedPhone){
                        this.contactPhoneCode = '';
                        this.contactPhoneNumber = this.form.Relationships[i].Contact.Phones[0].phone;
                    } else {
                        this.contactPhoneCode = parseEditedPhone.countryCallingCode;
                        this.contactPhoneNumber = parseEditedPhone.nationalNumber;
                    }
                }
                if(field === 'reps_phone'){
                    try {
                        var parseEditedPhone = parsePhoneNumber('+' + this.form.Representative?.Contact?.Phones[0]?.phone);
                    } catch(err) {
                    }
                    if(!parseEditedPhone){
                        this.repContactPhoneCode = '';
                        this.repContactPhoneNumber = this.form.Representative?.Contact?.Phones[0]?.phone;
                    } else {
                        this.repContactPhoneCode = parseEditedPhone.countryCallingCode;
                        this.repContactPhoneNumber = parseEditedPhone.nationalNumber;
                    }
                }
                this.edit = field;
            },
            displayStateName(value){
                var filteredEntry = this.states.filter(function( obj ) {
                    return obj.value === value;
                });
                if(filteredEntry?.length > 0){
                    return filteredEntry[0].label;
                } else {
                    return 'value';
                }
            },
            checkAddressesIsEmpty(){
                if(this.contact && this.contact.Addresses && this.contact.Addresses?.length === 0){
                    this.panel = this.panel.filter(function(s) {
                        return s !== 1;
                    });
                    this.disableAddressesPanel = true;
                } else {
                    this.disableAddressesPanel = false;
                }
            },
            checkContactsIsEmpty(){
                if(this.contact && this.contactCopy.Relationships && this.contactCopy.Relationships?.length === 0){
                    this.panel = this.panel.filter(function(s) {
                        return s !== 2;
                    });
                    this.disableContactsPanel = true;
                } else {
                    this.disableContactsPanel = false;
                }
            },
            updatePhoneNumber(data){
                // dependency is buggy, country selector does not work properly
            }, 
            hoverRevert(){
                Object.keys(this.hovering).forEach(i => this.hovering[i] = false)
            },
            checkValidPhone(code, number){
                const emailPhoneError= "Please enter valid phone number(s) before continuing."
                const emailPhoneRequired = this.errors.items.indexOf(emailPhoneError)

                if(!this.validateEmailPhone() && emailPhoneRequired >= 0){
                  this.errors.items.splice(emailPhoneRequired);
                }
              if(code && number){
                var parsedPhoneNumber = parsePhoneNumber('+' + code + number);

                var r = null;
                if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                  this.invalidPhone = false;
                  r = true;
                } else if((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number?.length > 0) {
                  this.invalidPhone = true;
                  r = false;
                } else {
                  this.invalidPhone = false;
                  r = true;
                }
                return r;
              }
            },
            setLeaseOptions() {
                const leases = this.contact.Leases.filter(l => !(moment(l.end_date).startOf('day') <= moment().startOf('day')) );
                this.leaseOptions = leases.map(lease => ({
                    id: lease.id,
                    Lease: lease,
                }));
            },

            showEditDesignationEvent(params) {
                if(!this.hasPermission('edit_business_rep_and_alc')){
                    this.showMessageNotification({ description: 'You do not have permission to add/edit/remove the Business Representative & Alternate Contact.' }) 
                    return;
                }
                let { contact, index } = params;
                let indexes = this.form.Relationships.map((_, j) => j).filter(i => i !== index);
                for (let j = 0; j < indexes.length; j++) {
                    this.closeEditEvent(indexes[j]);
                }
                this.activeEdit = [index];
            },

            closeEditEvent(params) {
                let relationData = this.form.Relationships[params];
                this.form.Relationships[params].selected_leases_list = relationData.lease_data && relationData.lease_data.length ? relationData.lease_data.map(item => {
                    return {
                        id: item.lease,
                        name: item.unit_number
                    }
                }) : []
                this.activeEdit = this.activeEdit.filter(number => number !== params);
            }
        },
        watch:{
            contact_id(contact_id){
                this.id = contact_id;
              //  this.fetchData();
            },
            contact(){
                this.updateForm(JSON.parse(JSON.stringify(this.contact)));
            },
            'contact.Addresses'(){
                this.checkAddressesIsEmpty();
            },
            'contact.Relationships'(){
                this.checkContactsIsEmpty();
            },
            panel(){
                if(this.panel.includes(3)){
                    this.form.active_military = true;
                } else {
                    this.form.active_military = false;
                }
            },
            propertyView(){
                this.updateForm(JSON.parse(JSON.stringify(this.contact)));
            }
        }

    }
</script>

<style>
    .designate_as_types>.col>div>.row>.hb-aviary-form-label{
        min-width: 33.4% !important;
        max-width: 33.4% !important;
    }
    .hide-form-label .hb-aviary-form-label.hb-aviary-form-padding {
        display: none;
    }
    .hide-form-label .hb-combobox-wrapper {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
</style>

<style scoped>
    .overview-panel{
        padding: 12px 24px 12px 24px;
        background: #F9FAFB;
    }
    .delete:hover {
        cursor: pointer;
    }
    .input-font-size {
        font-size: 14px !important;
    }
    .table-head.light{
        background: white;
        border-bottom: 2px solid #dce8ef;
        padding:8px 10px;
        text-transform: uppercase;
        font-size: 11px;
    }
    .overview-phone-label{
        color: #798f9a;
        font-size: 11px;
        line-height: 20px;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0;
        display: inline-block;
        vertical-align: top;
    }

    .contact-holder{
        border-bottom:1px dashed #e2e2e2;
        padding: 10px 0;

    }
    .contact-holder:last-child{
        border-bottom: none;
    }
    .contact-holder:first-child{
        padding-top: 0;
    }

    .address-holder{
        display: inline-block;
        width: 200px;
        vertical-align: top;

     }

    .interaction-text .horizontal-form-input{
        padding-bottom: 10px;
    }
    .interaction-text {
        padding: 16px 0 16px 24px;
        background-color: white;
    }
    .interaction-details {
        padding: 16px 0 16px 24px;
    }
    .custom-description {
        border-bottom: 1px solid #dce8ef !important;
    }
    .custom-edit {
        color: #00848E;
        cursor: pointer;
    }
    .interaction:last-of-type {
        border-bottom: none;
    }

    .interaction-section{
        margin-bottom: 8px;
        border-radius: 4px;
        box-shadow: 0px 2px 0px rgba(11, 18, 29, 0.1), 0px 2px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
    }
    .interaction-section-body{
        height: 0;
        overflow:hidden;
        background: white;
    }
    .interaction-section-body.open{
        height: auto;
        border-top: 1px solid #dce8ef;
    }
    .interaction-section h2{
        font-size: 14px;
        line-height: 24px;
        color: #101318;
        background-color: #FFFFFF;
        box-sizing: border-box;
        font-weight: normal;
        margin: 0;
    }
    .font-14px{
        font-size: 14px;
    }
    .custom-styling{
        margin-top: 25px;
    }
    .custom-styling input{
        border: none !important;
        border-bottom: 1px solid #949494 !important;
    }
    .custom-styling .input-tel.input-phone-number {
        margin-left: 10px;
    }
    .custom-styling .input-tel.input-phone-number{
        border-bottom-right-radius: 0px !important;
     }
    .custom-styling .input-tel.input-phone-number input:focus{
        box-shadow: none !important;
        outline: none !important;
        border-radius: 0px !important;


    }
    .custom-styling .select-country-container .country-selector.input-country-selector{
        box-shadow: none !important;
        outline: none !important;
        border-radius: 0px !important;
        width: 50px !important;
    }
    .custom-styling .select-country-container .country-selector.input-country-selector.has-value input:focus{
        box-shadow: none !important;
        outline: none !important;
        border-radius: 0px !important;
        width: 50px !important;
    }
    .alignment{
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 16px;
        padding-left: 16px;
    }
    .padding-top{
        padding-top: 0 !important;
    }
    .w-135{
        width: 135px;
    }

    .space-designation-col {
        position: relative;
    }

    .edit-icon-contact {
        position: absolute;
        right: 6px;
        top: 10px;
        cursor: pointer;
        display: none;
        transform: translateY(-50%);
    }
    .space-designation-col:hover .edit-icon-contact {
        display: block;
    }

    .padding-top-22 {
        padding-top:22px !important;
    }

    .hb-expansion-panel-wrapper:first-child {
        margin-top: 0!important;
    }
</style>