<template>
  <div>
    <hb-modal
      size="medium"
      confirmation
      title="Confirm Change"
      v-model="resetToDefaultProfile"
      @close="resetToDefaultProfile = false"
      :showHelpLink="false"
    >
      <template v-slot:content>
        <v-row
          v-if="loading"
          style="height: 200px;"
          align="center"
          justify="center"
          no-gutters
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <span v-else>
          <v-row class="px-7 py-3 hb-font-body">
            <div>You are about to reset to default billing profile.</div>
          </v-row>

          <div class="px-4 hb-font-body" v-if="prorate_invoice?.lease_id">
            <v-row class="ma-0 pa-0 hb-lh-0">
              <v-col cols="6" class="mx-0 px-0">Next Rent Due</v-col>
              <v-col cols="6" class="mx-0 px-0 text-right">{{
                prorate_invoice.due | formatLocalShortDate
              }}</v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">Prorate Days</v-col>
              <v-col cols="6" class="ma-0 pa-0 text-right"
                >{{ prorate_days }} days</v-col
              >
            </v-row>
            <v-row
              class="ma-0 pa-0"
              v-for="(lines, i) in prorate_invoice.InvoiceLines"
              :key="'invoiceLine' + i"
            >
              <v-col
                cols="6"
                class="ma-0 pa-0"
                v-if="lines.Service.name == 'Rent'"
                >Prorated {{ lines.Service.name }}</v-col
              >
              <v-col cols="6" class="ma-0 pa-0" v-else>{{
                lines.Service.name
              }}</v-col>
              <v-col cols="6" class="ma-0 pa-0 text-right"
                >{{ (lines.cost * lines.qty) | formatMoney }}
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">Rental Tax</v-col>
              <v-col cols="6" class="ma-0 pa-0 text-right"
                >{{ prorate_invoice.total_tax | formatMoney }}
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">Discounts</v-col>
              <v-col cols="6" class="ma-0 pa-0 text-right"
                >({{ prorate_invoice.discounts | formatMoney }})
              </v-col>
            </v-row>
            <v-row class="mx-0 px-0 font-weight-medium">
              <v-col cols="6" class="ma-0 pa-0">Balance Due</v-col>
              <v-col cols="6" class="ma-0 pa-0 text-right"
                >{{ prorate_invoice.balance | formatMoney }}
              </v-col>
            </v-row>
          </div>
          <v-row class="px-7 py-3 hb-font-body">
            <div>Do you want to continue with these changes?</div>
          </v-row>
        </span>
      </template>
      <template v-slot:right-actions>
        <hb-btn @click="updateLease" :color="prorate_invoice?.balance > 0 ? 'secondary' : 'primary'" :loading="btnLoading" :disabled="btnLoading || loading">{{ prorate_invoice?.balance > 0 ? 'Skip Payment' : 'Continue' }}</hb-btn>
        <hb-btn v-if="prorate_invoice?.balance > 0" @click="processPayment(false)" :disabled="loading || btnLoading">Continue</hb-btn>
      </template>
    </hb-modal>

    <BillingProfileTakePaymentModal
      v-model="show_take_payment_modal"
      v-if="show_take_payment_modal"
      :contact="contact"
      :lease="lease"
      source="RESET_TO_DEFAULT"
      @close="cancel"
      @update="updateLease"
    >
    </BillingProfileTakePaymentModal>
  </div>
</template>

<script>
import { calendarMixin } from "../../../mixins/calendarMixin";
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import BillingProfileTakePaymentModal from './BillingProfileTakePaymentModal.vue';
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from '../../../EventBus.js';
import moment from 'moment';

export default {
  name: "ResetToDefaultProfile",
  mixins: [ calendarMixin, notificationMixin ],
  props: ["contact", "lease", "value"],
  data() {
    return {
      show_take_payment_modal: false,
      loading: false,
      btnLoading: false,
      prorate_invoice: [],
      prorate_days: 0,
      canProcessPayment: false
    };
  },
  components: {
    BillingProfileTakePaymentModal
  },

  created() {
    this.getEstimates();
  },
  methods: {
    ...mapActions({
      setDefaultBillingProfileForLease : 'billingProfileStore/setDefaultBillingProfileForLease',
    }),
    ...mapMutations({
      setPaymentSource: 'paymentsStore/setPaymentSource',
      setInvoicesObject : 'paymentsStore/setInvoicesObject',
      resetPayments: 'paymentsStore/resetPayments',
      pushInvoices : 'paymentsStore/pushInvoices',
      createLeaseStructure: 'paymentsStore/createLeaseStructure',
      setLeaseProperty: 'paymentsStore/setLeaseProperty',
      setLeaseBilledMonths: 'paymentsStore/setLeaseBilledMonths'
    }),
    updateLease(paymentInfo = {}){
      let data = {
        contact: this.contact,
        lease: this.lease,
        dryrun: false,
        payment_details: paymentInfo?.payment_details
      }

      this.setDefaultBillingProfileForLease(data).then(r => {
        this.btnLoading = true;
        this.showMessageNotification({ type: 'success', description: 'Default billing profile has been set for this lease.' });
        this.resetToDefaultProfile = false;
        this.show_take_payment_modal = false;
        EventBus.$emit('reload_tenant_profile_leases');
        this.btnLoading = false;
      }).catch(error => {
        this.showMessageNotification({type: 'error', description: error});
        this.btnLoading = false;
      })
    },
    getEstimates(){
      this.loading = true;
      this.setDefaultBillingProfileForLease({contact: this.contact, lease: this.lease, dryrun: true}).then(r => {

      if (r?.bill_day_data[0]?.invoices.change_bill_day_invoices.length) {
        if (r?.bill_day_data[0]?.invoices.change_bill_day_invoices[0].balance > 0) {
          this.prorate_invoice = r?.bill_day_data[0]?.invoices?.change_bill_day_invoices[0];

          this.setPaymentSource({
            paymentSource: "BILL_DAY_CHANGE"
          });
          this.createLeaseStructure({
            invoices: r?.bill_day_data[0]?.invoices.change_bill_day_invoices,
            lease: Object.assign({}, this.lease),
            unit: this.lease.Unit,
            updateInvoice: false
          });
          this.setLeaseProperty({
            leaseIndex: 0,
            property: "OpenInvoices",
            propertyValue: r?.bill_day_data[0]?.invoices.open_invoices
          });
          this.setInvoicesObject({
            invoices: [
              ...r?.bill_day_data[0]?.invoices.open_invoices,
              ...r?.bill_day_data[0]?.invoices.change_bill_day_invoices
            ]
          });
          this.setLeaseBilledMonths({
            leaseIndex: 0,
            billedMonths: 0
          });
          this.prorate_days =
            this.dateDifferenceInDays(
              moment(this.prorate_invoice.period_start, "YYYY-MM-DD").startOf(
                "day"
              ),
              moment(this.prorate_invoice.period_end, "YYYY-MM-DD").startOf(
                "day"
              )
            ) + 1;
        }
      }

      this.loading = false;
      }).catch(error => {
        this.showMessageNotification({type: 'error', description: error});
      })
    },
    processPayment(){
      this.show_take_payment_modal = true;
    },
    cancel(){
      this.resetToDefaultProfile = false;
      this.show_take_payment_modal = false;
      this.resetPayments();
    },
  },
  computed:{
    resetToDefaultProfile: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .hb-lh-0{
    line-height: 0px;
  }
  .hb-bill-day-panel {
    background-color: transparent !important;
  }
  .row {
    margin-right: 0px !important;
  }
</style>
